import React from 'react';
import { Icon, Touchable, useTheme } from '@draftbit/ui';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { systemWeights } from 'react-native-typography';
import LinkingConfiguration from './LinkingConfiguration';
import CatalogueScreen from './screens/CatalogueScreen';
import CommandeconfirmeScreen from './screens/CommandeconfirmeScreen';
import EpiceriePaysanneScreen from './screens/EpiceriePaysanneScreen';
import H1HistoriqueScreen from './screens/H1HistoriqueScreen';
import H2DtailscommandeScreen from './screens/H2DtailscommandeScreen';
import HH1HistoriqueScreen from './screens/HH1HistoriqueScreen';
import M1MarchScreen from './screens/M1MarchScreen';
import M2ProduitScreen from './screens/M2ProduitScreen';
import PanierScreen from './screens/PanierScreen';
import ProduitScreen from './screens/ProduitScreen';
import palettes from './themes/palettes';
import Breakpoints from './utils/Breakpoints';
import useWindowDimensions from './utils/useWindowDimensions';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

function DefaultDrawerIcon({ tintColor, navigation }) {
  return (
    <Touchable
      onPress={() => navigation.toggleDrawer()}
      style={[styles.headerContainer, styles.headerContainerLeft]}
    >
      <Icon
        name="EvilIcons/navicon"
        size={27}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </Touchable>
  );
}

function Auth() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="AccueilScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerMode: 'none',
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="EpiceriePaysanneScreen"
        component={EpiceriePaysanneScreen}
        options={({ navigation }) => ({
          title: 'Epicerie Paysanne',
        })}
      />
    </Stack.Navigator>
  );
}

function Commande() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="CatalogueScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerMode: 'none',
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="ProduitScreen"
        component={ProduitScreen}
        options={({ navigation }) => ({
          title: '2. Produit',
        })}
      />
      <Stack.Screen
        name="CatalogueScreen"
        component={CatalogueScreen}
        options={({ navigation }) => ({
          title: '1. Catalogue',
        })}
      />
      <Stack.Screen
        name="CommandeconfirmeScreen"
        component={CommandeconfirmeScreen}
        options={({ navigation }) => ({
          title: '4. Commande confirmée',
        })}
      />
    </Stack.Navigator>
  );
}

function Historique() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="H1HistoriqueScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerLeft: ({ tintColor, canGoBack }) =>
          canGoBack ? (
            <View style={[styles.headerContainer, styles.headerContainerLeft]}>
              <Icon
                name="Feather/arrow-left"
                size={Platform.OS === 'ios' ? 21 : 24}
                color={tintColor}
                style={[styles.headerIcon, styles.headerIconLeft]}
              />
              <View style={styles.headerLabelWrapper}>
                <Text style={[styles.headerLabel]}>Retour</Text>
              </View>
            </View>
          ) : null,
        headerMode: 'none',
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="H1HistoriqueScreen"
        component={H1HistoriqueScreen}
        options={({ navigation }) => ({
          title: 'H1. Historique',
        })}
      />
      <Stack.Screen
        name="H2DtailscommandeScreen"
        component={H2DtailscommandeScreen}
        options={({ navigation }) => ({
          title: 'H2. Détails commande',
        })}
      />
    </Stack.Navigator>
  );
}

function LeMarch() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="M1MarchScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="M1MarchScreen"
        component={M1MarchScreen}
        options={({ navigation }) => ({
          title: 'M1. Marché',
        })}
      />
      <Stack.Screen
        name="M2ProduitScreen"
        component={M2ProduitScreen}
        options={({ navigation }) => ({
          title: 'M2. Produit',
        })}
      />
    </Stack.Navigator>
  );
}

function BottomTabNavigator() {
  const theme = useTheme();

  const tabBarOrDrawerIcons = {
    Commande: 'MaterialCommunityIcons/carrot',
    PanierScreen: 'Feather/shopping-cart',
    Historique: 'MaterialCommunityIcons/history',
    LeMarch: 'Feather/shuffle',
  };

  return (
    <Tab.Navigator
      initialRouteName="Commande"
      backBehavior="history"
      screenOptions={({ navigation }) => ({
        headerShown: false,
        tabBarIconStyle: { display: 'none' },
        tabBarShowLabel: true,
        tabBarStyle: { borderTopColor: 'transparent' },
      })}
    >
      <Tab.Screen
        name="Commande"
        component={Commande}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="MaterialCommunityIcons/carrot"
              size={25}
              color={focused ? theme.colors.branding.secondary : color}
            />
          ),
          tabBarLabel: 'Epicerie P.',
          title: 'Commande',
        })}
      />
      <Tab.Screen
        name="PanierScreen"
        component={PanierScreen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="Feather/shopping-cart"
              size={25}
              color={focused ? theme.colors.branding.secondary : color}
            />
          ),
          tabBarLabel: 'Panier',
          title: '3. Panier',
        })}
      />
      <Tab.Screen
        name="Historique"
        component={Historique}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="MaterialCommunityIcons/history"
              size={25}
              color={focused ? theme.colors.branding.secondary : color}
            />
          ),
          tabBarLabel: 'Historique',
          title: 'Historique',
        })}
      />
      <Tab.Screen
        name="LeMarch"
        component={LeMarch}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="Feather/shuffle"
              size={25}
              color={focused ? theme.colors.branding.secondary : color}
            />
          ),
          tabBarLabel: 'Le Marché',
          title: 'Le March',
        })}
      />
    </Tab.Navigator>
  );
}

export default function RootAppNavigator() {
  const theme = useTheme();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#FFFFFF',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="Auth"
        screenOptions={({ navigation }) => ({
          cardStyle: { flex: 1 },
          headerBackImage:
            Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
          headerMode: 'none',
          headerShown: false,
        })}
      >
        <Stack.Screen
          name="HH1HistoriqueScreen"
          component={HH1HistoriqueScreen}
          options={({ navigation }) => ({
            title: 'HH1. Historique',
          })}
        />
        <Stack.Screen
          name="Auth"
          component={Auth}
          options={({ navigation }) => ({
            title: 'Auth',
          })}
        />
        <Stack.Screen
          name="BottomTabNavigator"
          component={BottomTabNavigator}
          options={({ navigation }) => ({
            title: 'Bottom Tab Navigator',
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
  headerLabel: { fontSize: 17, letterSpacing: 0.35 },
  headerLabelWrapper: { flexDirection: 'row', alignItems: 'flex-start' },
});
