import React from 'react';
import {
  AccordionGroup,
  Button,
  Divider,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as CustomCode from '../CustomCode';
import * as NinoxApi from '../apis/NinoxApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const H1HistoriqueScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [show_direct, setShow_direct] = React.useState(false);
  const filterList = list => {
    // console.log(list,textInputValue)
    let lowerfiltertext = textInputValue.toLowerCase();
    let newList = list.filter(item =>
      item.nom.toLowerCase().includes(lowerfiltertext)
    );
    console.log(newList);
    return newList;
  };

  const setDeliveryDate_today = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    return tomorrow.setDate(tomorrow.getDate() + 1);
  };

  const emptytest = array => {
    const arraylength = array.length;
    const test = arraylength == 0 ? true : false;
    return test;
  };

  const LivraisonRemplie = datePicker => {
    let date = datePicker;
    let response = date > 0 ? false : true;
    return response;
  };

  const getDateMonth = date => {
    const datePicker = new Date(date);
    const datePicker_month = datePicker.getMonth() + 1;
    return datePicker_month;
  };

  const getDateDay = date => {
    const datePicker = new Date(date);
    const datePicker_day = datePicker.getDate();
    return datePicker_day;
  };

  const getDateYear = date => {
    const datePicker = new Date(date);
    const datePicker_year = datePicker.getFullYear();
    return datePicker_year;
  };

  const getDateString = date => {
    const datetoformat = new Date(date);
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    const datestring = datetoformat.toLocaleDateString('fr-FR', options);

    return datestring;
  };

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { flex: 1, marginLeft: 20, marginRight: 20 },
        dimensions.width
      )}
    >
      <Text
        accessible={true}
        selectable={false}
        style={StyleSheet.applyWidth(
          {
            color: theme.colors.text.strong,
            fontFamily: 'System',
            fontSize: 24,
            fontWeight: '600',
            textDecorationLine: 'underline',
          },
          dimensions.width
        )}
      >
        {"Commandes passées auprès de l'Epicerie Paysanne"}
      </Text>
      {/* Switch */}
      <>
        {!false ? null : (
          <View
            style={StyleSheet.applyWidth(
              { marginBottom: 10 },
              dimensions.width
            )}
          >
            {/* livraison_EP */}
            <>
              {show_direct ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', justifyContent: 'center' },
                    dimensions.width
                  )}
                >
                  {/* show_direct */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setShow_direct(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: 'transparent',
                        borderColor: theme.colors.branding.primary,
                        borderRadius: 8,
                        borderWidth: 1,
                        color: theme.colors.branding.primary,
                        fontFamily: 'System',
                        fontWeight: '700',
                        marginTop: 10,
                        maxWidth: '60%',
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={
                      'Voir les commandes passées en direct auprès des fermes'
                    }
                  />
                </View>
              )}
            </>
            {/* livraison_direct */}
            <>
              {!show_direct ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', justifyContent: 'center' },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'System',
                        fontSize: 24,
                        fontWeight: '600',
                        textDecorationLine: 'underline',
                      },
                      dimensions.width
                    )}
                  >
                    {'Commandes passées en direct auprès des fermes'}
                  </Text>
                  {/* show_EP */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setShow_direct(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: 'transparent',
                        borderColor: theme.colors.branding.primary,
                        borderRadius: 8,
                        borderWidth: 1,
                        fontFamily: 'System',
                        fontWeight: '700',
                        marginTop: 10,
                        maxWidth: '60%',
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={
                      "Voir les commandes passées auprès de l'Epicerie Paysanne"
                    }
                  />
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* historique_EP */}
      <>
        {show_direct ? null : (
          <ScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
          >
            <NinoxApi.FetchHistoriqueCommandeEPGET
              emailclient={Constants['emailclient']}
            >
              {({ loading, error, data, refetchHistoriqueCommandeEP }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <FlatList
                    data={fetchData}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ??
                      listData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(listData)
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'yyiQDj5E'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('BottomTabNavigator', {
                                screen: 'Historique',
                                params: {
                                  screen: 'H2DtailscommandeScreen',
                                  params: {
                                    idorder: listData?.id,
                                    date: getDateString(listData?.Livraison),
                                  },
                                },
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { maxWidth: '100%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingBottom: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  flexDirection: 'row',
                                  width: '70%',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Date */}
                              <Text
                                accessible={true}
                                selectable={false}
                                numberOfLines={2}
                                style={StyleSheet.applyWidth(
                                  { fontSize: 15 },
                                  dimensions.width
                                )}
                              >
                                {getDateString(listData?.Livraison)}
                              </Text>
                              {/* test_apreparer */}
                              <>
                                {!listData?.test_enattente ? null : (
                                  <Icon
                                    color={theme.colors.branding.primary}
                                    name={'FontAwesome/hourglass-o'}
                                    size={18}
                                    style={StyleSheet.applyWidth(
                                      {
                                        left: 140,
                                        marginLeft: 5,
                                        position: 'absolute',
                                      },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </View>

                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                { fontSize: 15 },
                                dimensions.width
                              )}
                            >
                              {Math.floor(listData?.['Montant HT final'])}
                              {' €'}
                            </Text>
                          </View>
                          <Divider
                            color={theme.colors.border.brand}
                            style={StyleSheet.applyWidth(
                              { height: 1 },
                              dimensions.width
                            )}
                          />
                        </Touchable>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                  />
                );
              }}
            </NinoxApi.FetchHistoriqueCommandeEPGET>
          </ScrollView>
        )}
      </>
      {/* historique_direct */}
      <>
        {!show_direct ? null : (
          <ScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
          >
            <NinoxApi.FetchHistoriqueCommandeDirectGET
              emailclient={Constants['emailclient']}
            >
              {({ loading, error, data, refetchHistoriqueCommandeDirect }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* List_order_collecte */}
                    <FlatList
                      data={fetchData}
                      horizontal={false}
                      inverted={false}
                      keyExtractor={(listOrderCollecteData, index) =>
                        listOrderCollecteData?.id ??
                        listOrderCollecteData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(listOrderCollecteData)
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'d16v4LA4'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listOrderCollecteData = item;
                        return (
                          <>
                            {/* à peser */}
                            <>
                              {!listOrderCollecteData?.test_apreparer ? null : (
                                <AccordionGroup
                                  caretSize={24}
                                  iconSize={24}
                                  icon={
                                    'MaterialCommunityIcons/weight-kilogram'
                                  }
                                  label={
                                    listOrderCollecteData?.label_order_collecte
                                  }
                                  style={StyleSheet.applyWidth(
                                    {
                                      fontSize: 14,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      paddingTop: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'flex-start',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        paddingBottom: 8,
                                        paddingTop: 8,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.text.strong,
                                          fontFamily: 'System',
                                          fontWeight: '600',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'Commande '}
                                      {listOrderCollecteData?.statut}
                                    </Text>
                                    <>
                                      {listOrderCollecteData?.test_apreparer ? null : (
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          style={StyleSheet.applyWidth(
                                            {
                                              fontFamily: 'System',
                                              fontSize: 15,
                                              fontWeight: '600',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {listOrderCollecteData?.Montant_total}
                                          {' €'}
                                        </Text>
                                      )}
                                    </>
                                  </View>
                                  {/* List_panier_pro_direct */}
                                  <FlatList
                                    data={
                                      listOrderCollecteData?.Panier_pro_direct
                                    }
                                    horizontal={false}
                                    inverted={false}
                                    keyExtractor={(
                                      listPanierProDirectData,
                                      index
                                    ) =>
                                      listPanierProDirectData?.id ??
                                      listPanierProDirectData?.uuid ??
                                      index?.toString() ??
                                      JSON.stringify(listPanierProDirectData)
                                    }
                                    keyboardShouldPersistTaps={'never'}
                                    listKey={JSON.stringify(
                                      listOrderCollecteData?.Panier_pro_direct
                                    )}
                                    nestedScrollEnabled={false}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const listPanierProDirectData = item;
                                      return (
                                        <>
                                          {/* Global */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors.text.strong,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {listPanierProDirectData?.Produit}
                                            </Text>
                                            <>
                                              {listOrderCollecteData?.test_apreparer ? null : (
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        theme.colors.text
                                                          .strong,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Total: '}
                                                  {
                                                    listOrderCollecteData?.Montant_total
                                                  }
                                                  {' €'}
                                                </Text>
                                              )}
                                            </>
                                          </View>
                                          {/* Détails */}
                                          <View>
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors.text.strong,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {'Quantités demandées: '}
                                              <>
                                                {!listPanierProDirectData?.aupoids ? null : (
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors.text
                                                            .strong,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      listPanierProDirectData?.aupoids
                                                    }
                                                  </Text>
                                                )}
                                              </>
                                              <>
                                                {!listPanierProDirectData?.alapiece ? null : (
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors.text
                                                            .strong,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      listPanierProDirectData?.alapiece
                                                    }
                                                  </Text>
                                                )}
                                              </>
                                            </Text>
                                            <>
                                              {!listOrderCollecteData?.test_prete ? null : (
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        theme.colors.text
                                                          .strong,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Facturé: '}
                                                  {
                                                    listPanierProDirectData?.facture
                                                  }{' '}
                                                  {
                                                    listPanierProDirectData?.unit
                                                  }
                                                </Text>
                                              )}
                                            </>
                                          </View>
                                          <Divider
                                            color={theme.colors.border.brand}
                                            style={StyleSheet.applyWidth(
                                              {
                                                height: 1,
                                                marginBottom: 5,
                                                marginLeft: 10,
                                                marginRight: 10,
                                                marginTop: 5,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        </>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                    contentContainerStyle={StyleSheet.applyWidth(
                                      { flex: 1 },
                                      dimensions.width
                                    )}
                                  />
                                </AccordionGroup>
                              )}
                            </>
                            {/* prête */}
                            <>
                              {!listOrderCollecteData?.test_prete ? null : (
                                <AccordionGroup
                                  caretSize={24}
                                  iconSize={24}
                                  icon={'Feather/truck'}
                                  label={
                                    listOrderCollecteData?.label_order_collecte
                                  }
                                  style={StyleSheet.applyWidth(
                                    {
                                      fontSize: 14,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      paddingTop: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'flex-start',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        paddingBottom: 8,
                                        paddingTop: 8,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.text.strong,
                                          fontFamily: 'System',
                                          fontWeight: '600',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'Commande '}
                                      {listOrderCollecteData?.statut}
                                    </Text>
                                    <>
                                      {listOrderCollecteData?.test_apreparer ? null : (
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          style={StyleSheet.applyWidth(
                                            {
                                              fontFamily: 'System',
                                              fontSize: 15,
                                              fontWeight: '600',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {listOrderCollecteData?.Montant_total}
                                          {' €'}
                                        </Text>
                                      )}
                                    </>
                                  </View>
                                  {/* List_panier_pro_direct */}
                                  <FlatList
                                    data={
                                      listOrderCollecteData?.Panier_pro_direct
                                    }
                                    horizontal={false}
                                    inverted={false}
                                    keyExtractor={(
                                      listPanierProDirectData,
                                      index
                                    ) =>
                                      listPanierProDirectData?.id ??
                                      listPanierProDirectData?.uuid ??
                                      index?.toString() ??
                                      JSON.stringify(listPanierProDirectData)
                                    }
                                    keyboardShouldPersistTaps={'never'}
                                    listKey={JSON.stringify(
                                      listOrderCollecteData?.Panier_pro_direct
                                    )}
                                    nestedScrollEnabled={false}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const listPanierProDirectData = item;
                                      return (
                                        <>
                                          {/* Global */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors.text.strong,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {listPanierProDirectData?.Produit}
                                            </Text>
                                            <>
                                              {listOrderCollecteData?.test_apreparer ? null : (
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        theme.colors.text
                                                          .strong,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Total: '}
                                                  {
                                                    listOrderCollecteData?.Montant_total
                                                  }
                                                  {' €'}
                                                </Text>
                                              )}
                                            </>
                                          </View>
                                          {/* Détails */}
                                          <View>
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors.text.strong,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {'Quantités demandées: '}
                                              <>
                                                {!listPanierProDirectData?.aupoids ? null : (
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors.text
                                                            .strong,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      listPanierProDirectData?.aupoids
                                                    }
                                                  </Text>
                                                )}
                                              </>
                                              <>
                                                {!listPanierProDirectData?.alapiece ? null : (
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors.text
                                                            .strong,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      listPanierProDirectData?.alapiece
                                                    }
                                                  </Text>
                                                )}
                                              </>
                                            </Text>
                                            <>
                                              {!listOrderCollecteData?.test_prete ? null : (
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        theme.colors.text
                                                          .strong,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Facturé: '}
                                                  {
                                                    listPanierProDirectData?.facture
                                                  }{' '}
                                                  {
                                                    listPanierProDirectData?.unit
                                                  }
                                                </Text>
                                              )}
                                            </>
                                          </View>
                                          <Divider
                                            color={theme.colors.border.brand}
                                            style={StyleSheet.applyWidth(
                                              {
                                                height: 1,
                                                marginBottom: 5,
                                                marginLeft: 10,
                                                marginRight: 10,
                                                marginTop: 5,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        </>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                    contentContainerStyle={StyleSheet.applyWidth(
                                      { flex: 1 },
                                      dimensions.width
                                    )}
                                  />
                                </AccordionGroup>
                              )}
                            </>
                            {/* livrée */}
                            <>
                              {!listOrderCollecteData?.test_livree ? null : (
                                <AccordionGroup
                                  caretSize={24}
                                  iconSize={24}
                                  icon={'Feather/check'}
                                  label={
                                    listOrderCollecteData?.label_order_collecte
                                  }
                                  style={StyleSheet.applyWidth(
                                    {
                                      fontSize: 14,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      paddingTop: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'flex-start',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        paddingBottom: 8,
                                        paddingTop: 8,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.text.strong,
                                          fontFamily: 'System',
                                          fontWeight: '600',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'Commande '}
                                      {listOrderCollecteData?.statut}
                                    </Text>
                                    <>
                                      {listOrderCollecteData?.test_apreparer ? null : (
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          style={StyleSheet.applyWidth(
                                            {
                                              fontFamily: 'System',
                                              fontSize: 15,
                                              fontWeight: '600',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {listOrderCollecteData?.Montant_total}
                                          {' €'}
                                        </Text>
                                      )}
                                    </>
                                  </View>
                                  {/* List_panier_pro_direct */}
                                  <FlatList
                                    data={
                                      listOrderCollecteData?.Panier_pro_direct
                                    }
                                    horizontal={false}
                                    inverted={false}
                                    keyExtractor={(
                                      listPanierProDirectData,
                                      index
                                    ) =>
                                      listPanierProDirectData?.id ??
                                      listPanierProDirectData?.uuid ??
                                      index?.toString() ??
                                      JSON.stringify(listPanierProDirectData)
                                    }
                                    keyboardShouldPersistTaps={'never'}
                                    listKey={JSON.stringify(
                                      listOrderCollecteData?.Panier_pro_direct
                                    )}
                                    nestedScrollEnabled={false}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const listPanierProDirectData = item;
                                      return (
                                        <>
                                          {/* Global */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors.text.strong,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {listPanierProDirectData?.Produit}
                                            </Text>
                                            <>
                                              {listOrderCollecteData?.test_apreparer ? null : (
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        theme.colors.text
                                                          .strong,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Total: '}
                                                  {
                                                    listOrderCollecteData?.Montant_total
                                                  }
                                                  {' €'}
                                                </Text>
                                              )}
                                            </>
                                          </View>
                                          {/* Détails */}
                                          <View>
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors.text.strong,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {'Quantités demandées: '}
                                              <>
                                                {!listPanierProDirectData?.aupoids ? null : (
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors.text
                                                            .strong,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      listPanierProDirectData?.aupoids
                                                    }
                                                  </Text>
                                                )}
                                              </>
                                              <>
                                                {!listPanierProDirectData?.alapiece ? null : (
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors.text
                                                            .strong,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      listPanierProDirectData?.alapiece
                                                    }
                                                  </Text>
                                                )}
                                              </>
                                            </Text>
                                            <>
                                              {!listOrderCollecteData?.test_prete ? null : (
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        theme.colors.text
                                                          .strong,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Facturé: '}
                                                  {
                                                    listPanierProDirectData?.facture
                                                  }{' '}
                                                  {
                                                    listPanierProDirectData?.unit
                                                  }
                                                </Text>
                                              )}
                                            </>
                                          </View>
                                          <Divider
                                            color={theme.colors.border.brand}
                                            style={StyleSheet.applyWidth(
                                              {
                                                height: 1,
                                                marginBottom: 5,
                                                marginLeft: 10,
                                                marginRight: 10,
                                                marginTop: 5,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        </>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                    contentContainerStyle={StyleSheet.applyWidth(
                                      { flex: 1 },
                                      dimensions.width
                                    )}
                                  />
                                </AccordionGroup>
                              )}
                            </>
                          </>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  </>
                );
              }}
            </NinoxApi.FetchHistoriqueCommandeDirectGET>
          </ScrollView>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(H1HistoriqueScreen);
