/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';
import { Platform } from 'react-native';

function renderLinkingPrefix() {
  try {
    return Linking.createURL('/');
  } catch (e) {
    return 'draftbit://';
  }
}

const prefix = renderLinkingPrefix();

const linking = {
  enabled: Platform.OS === 'web' ? false : true,
  prefixes: [prefix],
  config: {
    screens: {
      Auth: { screens: {} },
      BottomTabNavigator: {
        screens: {
          Commande: {
            screens: {
              ProduitScreen: {
                screens: {
                  ProduitScreen: {
                    path: 'ProduitScreen/:productID?',
                  },
                },
              },
            },
          },
          Historique: { screens: {} },
          LeMarch: {
            screens: {
              M1MarchScreen: {
                screens: {
                  M1MarchScreen: {
                    path: 'M1MarchScreen',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export default linking;
