import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const collecteDirectGET = async (
  Constants,
  { zone_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (zone_id !== undefined) {
    paramsDict[
      'query'
    ] = `(select Collecte where sum(Collecte,1000*60*60*7)>today() and 'Permettre en direct'=true and cnt(Zone[Zone=${renderParam(
      zone_id
    )}])>0 order by Collecte).{fermier:Producteur.'Nom de la ferme',label_order_collecte:concat(Producteur.'Nom de la ferme',format(sum(Collecte,1000*60*60*2),"ddd DD MMM hh:mm","fr")),heurecollecte:Collecte,logistique:text(Logistique),direct:'Permettre en direct',franco:Producteur.'Franco pour livraisons direct',produits:Arrivage.{label_order_collecte:concat(Collecte.Producteur.'Nom de la ferme',format(sum(Collecte.Collecte,1000*60*60*2),"ddd DD MMM hh:mm","fr")),fermier:Collecte.Producteur.'Nom de la ferme',collecte_id:number(Collecte.ID),heurecollecte:Collecte.Collecte,arrivageID:number(ID),product_id:number(Produit.ID),label:Produit.label,unit:Produit.Famille.'Unité de vente'.'Unité',famille:Produit.Famille.Nom,spec:Produit.Specificite,cond:Produit.Conditionnement,potentiel:'Quantité potentielle',demande:'Quantité demandée',facture:'Quantité facturée',prix_total:round('Coût HT'*100)/100,prix_ferme:round('Coût HT'*100)/100,com:0}}`;
  }
  const url = `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCollecteDirectGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['collecte_directs', args],
    () => collecteDirectGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchCollecteDirectGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  zone_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCollecteDirectGET(
    { zone_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCollecteDirect: refetch });
};

export const détailsCommandeGET = async (
  Constants,
  { idorder },
  handlers = {}
) => {
  const paramsDict = {};
  if (idorder !== undefined) {
    paramsDict['query'] = `(select Panier_pro where Commande=${renderParam(
      idorder
    )}).{Produit:Produit.SKU,'aupoids':'au poids','alapiece':'à la pièce',remarque:remarque,'facture':'facturé',Prix:'Prix HT Catalogue',unit:Produit.Famille.'Unité de vente'.'Unité',Remise:Remise,'Montant total':'Montant HT facturé'}`;
  }
  const url = `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDétailsCommandeGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['ninoxDétailsCommandeGET', args],
    () => détailsCommandeGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['ninoxDétailsCommandeGETS']),
    }
  );
};

export const FetchDétailsCommandeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  idorder,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDétailsCommandeGET(
    { idorder },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDétailsCommande: refetch });
};

export const getProduitsGET = async (
  Constants,
  { dateday, datemonth, dateyear },
  handlers = {}
) => {
  const paramsDict = {};
  if (
    dateday !== undefined &&
    datemonth !== undefined &&
    dateyear !== undefined
  ) {
    paramsDict[
      'query'
    ] = `(select Produit where orfx(cnt(Arrivage[Peremption>date(${renderParam(
      dateyear
    )},${renderParam(datemonth)},${renderParam(
      dateday
    )}) and Collecte.Collecte<=datetime(${renderParam(dateyear)},${renderParam(
      datemonth
    )},${renderParam(
      dateday
    )},9)])>0,'Précommande'=true) and 'Dispo Pro' = true order by concat(Famille.'Catégorie2'.Nom,label)).{id:number(ID),nom:label,precommande:'Précommande',conditionnement:'Conditionnement',prix:'Prix HT [PRO]',cat_label:text(Famille.'Catégorie2'.Label),catid:number(Famille.'Catégorie2'.ID),provenance:Provenance,img_url:shareFile(Image),note:Note,unit:text(Famille.'Unité de vente'.'Unité')}`;
  }
  const url = `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetProduitsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['produits', args],
    () => getProduitsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetProduitsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  dateday,
  datemonth,
  dateyear,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetProduitsGET(
    { dateday, datemonth, dateyear },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetProduits: refetch });
};

export const getProduits2GET = async (
  Constants,
  { dateday, datemonth, dateyear },
  handlers = {}
) => {
  const paramsDict = {};
  if (
    dateday !== undefined &&
    datemonth !== undefined &&
    dateyear !== undefined
  ) {
    paramsDict[
      'query'
    ] = `(select Produit where orfx(cnt(Arrivage[Peremption>date(${renderParam(
      dateyear
    )},${renderParam(datemonth)},${renderParam(
      dateday
    )}) and Collecte.Collecte<=datetime(${renderParam(dateyear)},${renderParam(
      datemonth
    )},${renderParam(
      dateday
    )},9)])>0,'Précommande'=true) and 'Dispo Pro' = true order by concat(Famille.'Catégorie2'.Nom,label)).{id:number(ID),nom:label}`;
  }
  const url = `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetProduits2GET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['produits', args],
    () => getProduits2GET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetProduits2GET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  dateday,
  datemonth,
  dateyear,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetProduits2GET(
    { dateday, datemonth, dateyear },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetProduits2: refetch });
};

export const historiqueCommandeEPGET = async (
  Constants,
  { emailclient },
  handlers = {}
) => {
  const paramsDict = {};
  if (emailclient !== undefined) {
    paramsDict[
      'query'
    ] = `(select Commande_pro where cnt(Professionnels.Chefs['E-mail'="${renderParam(
      emailclient
    )}"])>0 order by rsort).{id:number(ID),resto:Professionnels.'Nom commercial',Livraison:'Livraison','Montant HT final':'Montant total HT',test_enattente:Statut=2}`;
  }
  const url = `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useHistoriqueCommandeEPGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['ninoxHistoriqueCommandeEPGET', args],
    () => historiqueCommandeEPGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['ninoxHistoriqueCommandeEPGETS']),
    }
  );
};

export const FetchHistoriqueCommandeEPGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  emailclient,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useHistoriqueCommandeEPGET(
    { emailclient },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchHistoriqueCommandeEP: refetch,
  });
};

export const historiqueCommandeDirectGET = async (
  Constants,
  { emailclient },
  handlers = {}
) => {
  const paramsDict = {};
  if (emailclient !== undefined) {
    paramsDict[
      'query'
    ] = `(select Commande_pro_direct where cnt(Professionnels.Chefs['E-mail'="${renderParam(
      emailclient
    )}"])>0 order by rsort).{id:number(ID),label_order_collecte:concat(first(Panier_pro_direct.Arrivage.Collecte.Producteur.'Nom de la ferme'),format(sum(first(Panier_pro_direct.Arrivage.Collecte.Collecte),1000*60*60*2),"ddd DD MMM hh:mm","fr")),test_apreparer:if Statut=1 then true end,test_prete:if Statut=2 then true end,test_livree:if Statut=3 then true end,statut:text(Statut),resto:Professionnels.'Nom commercial',Ferme:unique(Panier_pro_direct.Arrivage.Collecte.Producteur.'Nom de la ferme'),Livraison:'Date',Montant_total:round('Montant total HT'*100)/100,'Montant_com':round('Montant com HT'*100)/100,Montant_ferme:'Montant ferme HT',Panier_pro_direct:Panier_pro_direct.{Produit:Arrivage.Produit.SKU,'aupoids':'au poids','alapiece':'à la pièce',remarque:Remarque,'facture':'facturé',Prix:'Prix HT direct',unit:Arrivage.Produit.Famille.'Unité de vente'.'Unité',Remise:Remise,'Montant ferme':'Montant HT ferme facturé','Montant total':'Montant total HT',com:'Commission HT'}}`;
  }
  const url = `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useHistoriqueCommandeDirectGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['order_collectes', args],
    () => historiqueCommandeDirectGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchHistoriqueCommandeDirectGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  emailclient,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useHistoriqueCommandeDirectGET(
    { emailclient },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchHistoriqueCommandeDirect: refetch,
  });
};

export const identificationRestoGET = async (
  Constants,
  { clientid },
  handlers = {}
) => {
  const paramsDict = {};
  if (clientid !== undefined) {
    paramsDict[
      'query'
    ] = `(select Restaurant where 'identifiant app'="${renderParam(
      clientid
    )}").{nomresto:'Nom commercial',idninox:ID}`;
  }
  const url = `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useIdentificationRestoGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['ninoxIdentificationRestoGET', args],
    () => identificationRestoGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['ninoxIdentificationRestoGETS']),
    }
  );
};

export const FetchIdentificationRestoGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  clientid,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useIdentificationRestoGET(
    { clientid },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchIdentificationResto: refetch,
  });
};

export const joursGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['query'] =
    '(select Livraisons where Date>=today() and Montrer=true order by Date).{label:Texte,value:Date}';
  const url = `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useJoursGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['jours', args], () => joursGET(Constants, args, handlers), {
    refetchInterval,
  });
};

export const FetchJoursGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useJoursGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchJours: refetch });
};

export const zoneProGET = async (Constants, { emailinput }, handlers = {}) => {
  const paramsDict = {};
  if (emailinput !== undefined) {
    paramsDict[
      'query'
    ] = `first((select Restaurant where cnt(Chefs['E-mail'="${renderParam(
      emailinput
    )}"])>0).Zone)`;
  }
  const url = `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useZoneProGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(['zone', args], () => zoneProGET(Constants, args, handlers), {
    refetchInterval,
    onSuccess: () => queryClient.invalidateQueries(['zones']),
  });
};

export const FetchZoneProGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  emailinput,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useZoneProGET(
    { emailinput },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchZonePro: refetch });
};

export const fermierDirectGET = async (
  Constants,
  { zone_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (zone_id !== undefined) {
    paramsDict[
      'query'
    ] = `(select Ferme where cnt(Collecte[date(Collecte)>=today() and 'Permettre en direct'=true and cnt(Zone[Zone=${renderParam(
      zone_id
    )}])>0])>0).{label:'Nom de la ferme',value:number(ID)}`;
  }
  const url = `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useFermierDirectGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['collecte_direct_fermiers', args],
    () => fermierDirectGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchFermierDirectGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  zone_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useFermierDirectGET(
    { zone_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchFermierDirect: refetch });
};
