import React from 'react';
import { Button, Divider, ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as CustomCode from '../CustomCode';
import * as NinoxApi from '../apis/NinoxApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { date: '', idorder: '' };

const H2DtailscommandeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [pickerValue, setPickerValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const LivraisonRemplie = datePicker => {
    let date = datePicker;
    let response = date > 0 ? false : true;
    return response;
  };

  const getDateDay = date => {
    const datePicker = new Date(date);
    const datePicker_day = datePicker.getDate();
    return datePicker_day;
  };

  const filterList = list => {
    // console.log(list,textInputValue)
    let lowerfiltertext = textInputValue.toLowerCase();
    let newList = list.filter(item =>
      item.nom.toLowerCase().includes(lowerfiltertext)
    );
    console.log(newList);
    return newList;
  };

  const setDeliveryDate_today = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    return tomorrow.setDate(tomorrow.getDate() + 1);
  };

  const getDateYear = date => {
    const datePicker = new Date(date);
    const datePicker_year = datePicker.getFullYear();
    return datePicker_year;
  };

  const emptytest = array => {
    const arraylength = array.length;
    const test = arraylength == 0 ? true : false;
    return test;
  };

  const getDateMonth = date => {
    const datePicker = new Date(date);
    const datePicker_month = datePicker.getMonth() + 1;
    return datePicker_month;
  };

  const getDateString = date => {
    const datetoformat = new Date(date);
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    const datestring = datetoformat.toLocaleDateString('fr-FR', options);

    return datestring;
  };

  const round2 = numbertoround => {
    let numb = numbertoround;
    const roundednumb = Math.round(numb);
    return roundednumb;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }

      const valueNnIWCqHl = setDeliveryDate_today(datePickerValue);
      setDatePickerValue(valueNnIWCqHl);
      const tomorrow = valueNnIWCqHl;
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 10,
            marginLeft: 20,
            marginRight: 20,
            marginTop: 10,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { maxWidth: '80%', width: '80%' },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                color: theme.colors.text.strong,
                fontFamily: 'PTSerif_700Bold',
                fontSize: 26,
              },
              dimensions.width
            )}
          >
            {'Historique'}
          </Text>
        </View>
        <Image
          resizeMode={'cover'}
          source={imageSource(Images['CarotteDetouree'])}
          style={StyleSheet.applyWidth(
            { height: 60, minWidth: '20%', width: 60 },
            dimensions.width
          )}
        />
      </View>

      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 10,
            marginLeft: 20,
            marginRight: 20,
            marginTop: 10,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { maxWidth: '80%', width: '80%' },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                color: theme.colors.text.strong,
                fontFamily: 'Rosario_700Bold',
                fontSize: 18,
              },
              dimensions.width
            )}
          >
            {'Commande du '}
            {props.route?.params?.date ?? defaultProps.date}
          </Text>
        </View>
      </View>

      <ScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        contentContainerStyle={StyleSheet.applyWidth(
          { marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        <NinoxApi.FetchDétailsCommandeGET
          idorder={props.route?.params?.idorder ?? defaultProps.idorder}
        >
          {({ loading, error, data, refetchDétailsCommande }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <FlatList
                data={fetchData}
                horizontal={false}
                inverted={false}
                keyExtractor={(listData, index) =>
                  listData?.id ??
                  listData?.uuid ??
                  index?.toString() ??
                  JSON.stringify(listData)
                }
                keyboardShouldPersistTaps={'never'}
                listKey={'T7haxOdJ'}
                nestedScrollEnabled={false}
                numColumns={1}
                onEndReachedThreshold={0.5}
                renderItem={({ item, index }) => {
                  const listData = item;
                  return (
                    <>
                      <View>
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              paddingBottom: 8,
                              paddingTop: 8,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Produit */}
                          <Text
                            accessible={true}
                            selectable={false}
                            numberOfLines={2}
                            style={StyleSheet.applyWidth(
                              {
                                fontFamily: 'System',
                                fontSize: 15,
                                fontWeight: '600',
                              },
                              dimensions.width
                            )}
                          >
                            {listData?.Produit}
                          </Text>
                        </View>

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '30%' },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                { color: theme.colors.text.strong },
                                dimensions.width
                              )}
                            >
                              {'Demande: '}
                            </Text>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '70%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* au poids */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                { color: theme.colors.text.strong },
                                dimensions.width
                              )}
                            >
                              {listData?.aupoids} {listData?.unit}
                            </Text>
                            {/* à la pièce */}
                            <>
                              {!listData?.alapiece ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    { color: theme.colors.text.strong },
                                    dimensions.width
                                  )}
                                >
                                  {listData?.alapiece}
                                  {' unité'}
                                </Text>
                              )}
                            </>
                            {/* remarque */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                { color: theme.colors.text.strong },
                                dimensions.width
                              )}
                            >
                              {listData?.remarque}
                            </Text>
                          </View>
                        </View>

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '30%' },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                { color: theme.colors.text.strong },
                                dimensions.width
                              )}
                            >
                              {'Préparé: '}
                            </Text>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '70%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* facturé */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                { color: theme.colors.text.strong },
                                dimensions.width
                              )}
                            >
                              {listData?.facture}
                            </Text>
                            {/* Prix catalogue */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                { color: theme.colors.text.strong },
                                dimensions.width
                              )}
                            >
                              {listData?.Prix}
                              {'€/'}
                              {listData?.unit}
                            </Text>
                            {/* Remise */}
                            <>
                              {!listData?.Remise ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    { color: theme.colors.text.strong },
                                    dimensions.width
                                  )}
                                >
                                  {listData?.Remise}
                                  {'%'}
                                </Text>
                              )}
                            </>
                            {/* montant */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                { fontSize: 15 },
                                dimensions.width
                              )}
                            >
                              {round2(listData?.['Montant total'])}
                              {' €'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <Divider
                        color={theme.colors.border.brand}
                        style={StyleSheet.applyWidth(
                          { height: 1, marginBottom: 2, marginTop: 2 },
                          dimensions.width
                        )}
                      />
                    </>
                  );
                }}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              />
            );
          }}
        </NinoxApi.FetchDétailsCommandeGET>
      </ScrollView>

      <View
        style={StyleSheet.applyWidth(
          {
            bottom: 0,
            height: 60,
            justifyContent: 'center',
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
          },
          dimensions.width
        )}
      >
        {/* CTA */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'Historique',
                params: { screen: 'H1HistoriqueScreen' },
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.branding.primary,
              fontFamily: 'System',
              fontWeight: '700',
              height: 35,
              textAlign: 'center',
            },
            dimensions.width
          )}
          title={"Retour à l'historique"}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(H2DtailscommandeScreen);
