import React from 'react';
import { DatePicker } from '@draftbit/ui';
import { StyleSheet, View } from 'react-native';

export const CustomDatePicker = () => {
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());

  return (
    <View style={styles.View_6y} pointerEvents={'auto'}>
      <DatePicker
        onDateChange={newDatePickerValue => {
          try {
            setDatePickerValue(newDatePickerValue);
          } catch (err) {
            console.error(err);
          }
        }}
        style={styles.DatePickery4}
        date={datePickerValue}
        label={'Date'}
        mode={'date'}
        leftIconMode={'inset'}
        format={'ddd dd, mmmm'}
        type={'solid'}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  Textdi: {
    fontSize: 20,
    fontFamily: 'AdventPro_600SemiBold',
    marginBottom: 30,
  },
  DatePickery4: {
    width: 200,
  },
  View_6y: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 50,
  },
});
