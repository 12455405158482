import React from 'react';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, Text, View } from 'react-native';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const CommandeconfirmeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [is_direct, setIs_direct] = React.useState(false);

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          justifyContent: 'space-evenly',
          marginLeft: 20,
          marginRight: 20,
        },
        dimensions.width
      )}
    >
      <View>
        {/* app-logo */}
        <Image
          resizeMode={'contain'}
          source={imageSource(Images['CarotteDetouree'])}
          style={StyleSheet.applyWidth(
            { height: 80, width: 180 },
            dimensions.width
          )}
        />
      </View>

      <View
        style={StyleSheet.applyWidth(
          { marginLeft: 16, marginRight: 16 },
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          selectable={false}
          style={StyleSheet.applyWidth(
            { fontSize: 30, marginBottom: 24, textAlign: 'center' },
            dimensions.width
          )}
        >
          {'Commande confirmée'}
        </Text>
      </View>

      <View
        style={StyleSheet.applyWidth(
          {
            bottom: 0,
            height: 100,
            justifyContent: 'space-between',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* CTA */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'Historique',
                params: { screen: 'H1HistoriqueScreen' },
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.branding.primary,
              fontFamily: 'System',
              fontWeight: '700',
              height: 35,
              textAlign: 'center',
            },
            dimensions.width
          )}
          title={"Accéder à l'historique"}
        />
        {/* Retour catalogue */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            try {
              navigation.navigate('CatalogueScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.branding.primary,
              fontFamily: 'System',
              fontWeight: '700',
              height: 35,
              textAlign: 'center',
            },
            dimensions.width
          )}
          title={'Retour au catalogue'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(CommandeconfirmeScreen);
