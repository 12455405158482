export default {
  Inter_300Light: require('../assets/fonts/Inter_300Light.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Merriweather_300Light: require('../assets/fonts/Merriweather_300Light.ttf'),
  Merriweather_700Bold_Italic: require('../assets/fonts/Merriweather_700Bold_Italic.ttf'),
  PTSerif_700Bold: require('../assets/fonts/PTSerif_700Bold.ttf'),
  PTSerif_400Regular_Italic: require('../assets/fonts/PTSerif_400Regular_Italic.ttf'),
  PTSerif_700Bold_Italic: require('../assets/fonts/PTSerif_700Bold_Italic.ttf'),
  Rosario_700Bold: require('../assets/fonts/Rosario_700Bold.ttf'),
  Rosario_400Regular: require('../assets/fonts/Rosario_400Regular.ttf'),
};
