import React from 'react';
import { Button, ScreenContainer, TextInput, withTheme } from '@draftbit/ui';
import { Image, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as CustomCode from '../CustomCode';
import * as NinoxApi from '../apis/NinoxApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const EpiceriePaysanneScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const trimInputValue = inputValue => {
    const trimmedValue = inputValue.trim();
    return trimmedValue;
  };

  return (
    <ScreenContainer hasSafeArea={true} scrollable={true}>
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            marginBottom: 16,
            marginLeft: 20,
            marginRight: 20,
            marginTop: 50,
          },
          dimensions.width
        )}
      >
        <Image
          resizeMode={'contain'}
          source={imageSource(Images['CarotteDetouree'])}
          style={StyleSheet.applyWidth(
            { height: 300, width: 300 },
            dimensions.width
          )}
        />
        <Text
          accessible={true}
          selectable={false}
          style={StyleSheet.applyWidth(
            {
              color: theme.colors.branding.primary,
              fontFamily: 'PTSerif_700Bold_Italic',
              fontSize: 30,
              textAlign: 'center',
              textDecorationColor: theme.colors.background.danger,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {'Epicerie Paysanne'}
        </Text>

        <Text
          accessible={true}
          selectable={false}
          style={StyleSheet.applyWidth(
            {
              color: theme.colors.text.strong,
              fontFamily: 'Rosario_400Regular',
              textTransform: 'uppercase',
            },
            dimensions.width
          )}
        >
          {'Fermier, local et de saison'}
        </Text>
      </View>

      <KeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
      >
        <View
          style={StyleSheet.applyWidth(
            { marginBottom: 60, marginLeft: 20, marginRight: 20 },
            dimensions.width
          )}
        >
          {/* email */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newEmailValue => {
              try {
                setGlobalVariableValue({
                  key: 'emailclient',
                  value: trimInputValue(newEmailValue),
                });
                console.log(newEmailValue);
                console.log(trimInputValue(newEmailValue));
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            defaultValue={Constants['emailclient']}
            placeholder={'Entrer votre email'}
            placeholderTextColor={theme.colors.text.light}
            selectTextOnFocus={true}
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: 1,
                borderColor: theme.colors.text.light,
                borderLeftWidth: 1,
                borderRadius: 8,
                borderRightWidth: 1,
                borderTopWidth: 1,
                fontSize: 16,
                marginBottom: 16,
                paddingBottom: 16,
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 16,
              },
              dimensions.width
            )}
            textContentType={'emailAddress'}
          />
          <View
            style={StyleSheet.applyWidth(
              { paddingBottom: 5, paddingTop: 5 },
              dimensions.width
            )}
          >
            {/* Prix */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontSize: 12,
                  marginBottom: 8,
                },
                dimensions.width
              )}
            >
              {"Les Prix s'entendent HT"}
            </Text>
            {/* Livraison */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                { color: theme.colors.text.strong, fontSize: 12 },
                dimensions.width
              )}
            >
              {
                'Livraisons sur la Côte de Beaune: du mardi au samedi\nLivraisons à Dijon: les mardis et vendredis fin de matinée'
              }
            </Text>
            {/* BL et facturation */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                { color: theme.colors.text.strong, fontSize: 12, marginTop: 8 },
                dimensions.width
              )}
            >
              {'BL à chaque livraison et facturation en fin de mois'}
            </Text>
          </View>
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              bottom: 0,
              height: 60,
              justifyContent: 'center',
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 20,
              position: 'absolute',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* Login */}
          <>
            {!Constants['emailclient'] ? null : (
              <Button
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const zone_api = (
                        await NinoxApi.zoneProGET(Constants, {
                          emailinput: Constants['emailclient'],
                        })
                      )?.json;
                      setGlobalVariableValue({
                        key: 'zone',
                        value: zone_api,
                      });
                      navigation.navigate('BottomTabNavigator', {
                        screen: 'Commande',
                        params: { screen: 'CatalogueScreen' },
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors.branding.primary,
                    fontFamily: 'System',
                    fontWeight: '700',
                    height: 35,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Se connecter'}
              />
            )}
          </>
        </View>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(EpiceriePaysanneScreen);
