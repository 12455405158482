import React from 'react';
import {
  AccordionGroup,
  Button,
  Divider,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlatList, Image, ScrollView, Text, View } from 'react-native';
import * as CustomCode from '../CustomCode';
import * as GlobalStyles from '../GlobalStyles.js';
import * as MakeApi from '../apis/MakeApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import getDate_Day from '../global-functions/getDate_Day';
import getDate_Month from '../global-functions/getDate_Month';
import getDate_Year from '../global-functions/getDate_Year';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { direct_view: '' };

const PanierScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [show_direct, setShow_direct] = React.useState(false);
  const extractProduct_unit = cartItem => {
    let unit = cartItem.unit;
    return unit;
  };

  const extractProduct_name = cartItem => {
    let name = cartItem.productname;
    return name;
  };

  const extractProduct_remark = CartItem => {
    let remarque = CartItem.Remarque;
    return remarque;
  };

  const extractProduct_id = cartItem => {
    let product_id = cartItem.productId;
    return product_id;
  };

  const extractProduct_qte = CartItem => {
    let qte = CartItem.qty;
    return qte;
  };

  const ShowQte = cartItem => {
    let qte = cartItem.qty;
    let response = qte > 0 ? true : false;
    return response;
  };

  const extractProduct_piece = CartItem => {
    let piece = CartItem.qty_piece;
    return piece;
  };

  const ShowQty_piece = cartItem => {
    let qte = cartItem.qty_piece;
    let response = qte > 0 ? true : false;
    return response;
  };

  const delete_order_collecte = collecte_id => {
    let newShoppingCart_direct = Constants.ShoppingCart_direct.filter(
      item => item.collecte_id !== collecte_id
    );
    return newShoppingCart_direct;
  };

  const delete_direct_cartItem = (product_id, collecte_id) => {
    let newShoppingCart_direct = Constants.ShoppingCart_direct;
    const collecte_order_to_update = newShoppingCart_direct.filter(
      item => item.collecte_id === collecte_id
    )[0];

    // set a newly updated cart for this specific order_collecte
    let newCart = collecte_order_to_update.cart.filter(
      item => item.productId !== product_id
    );

    // update the cart of the specific order_collecte

    let filterResult = newShoppingCart_direct.filter(
      item => item.collecte_id === collecte_id
    )[0];
    filterResult.cart = newCart;
    newShoppingCart_direct.filter(item => item.collecte_id === collecte_id)[0] =
      filterResult;

    return newShoppingCart_direct;
  };

  const deleteCartItem = product_id => {
    let newShoppingCart = Constants.ShoppingCart.filter(
      item => item.productId !== product_id
    );
    return newShoppingCart;
  };
  const makeOrderCollecteDirectPOST = MakeApi.useOrderCollecteDirectPOST();
  const makeOrderCreationPOST = MakeApi.useOrderCreationPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setShow_direct(
        props.route?.params?.direct_view ?? defaultProps.direct_view
      );
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { paddingLeft: 20, paddingRight: 20 },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            height: 60,
            justifyContent: 'space-between',
            marginBottom: 15,
          },
          dimensions.width
        )}
      >
        {/* Commande */}
        <Text
          accessible={true}
          selectable={false}
          style={StyleSheet.applyWidth(
            {
              alignSelf: 'center',
              color: theme.colors.text.strong,
              fontSize: 18,
              textDecorationLine: 'underline',
            },
            dimensions.width
          )}
        >
          {'Votre Panier'}
        </Text>
        <Image
          resizeMode={'cover'}
          source={imageSource(Images['CarotteDetouree'])}
          style={StyleSheet.applyWidth(
            { height: 60, width: 60 },
            dimensions.width
          )}
        />
      </View>
      {/* Switch */}
      <View
        style={StyleSheet.applyWidth({ marginBottom: 10 }, dimensions.width)}
      >
        {/* livraison_EP */}
        <>
          {show_direct ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', justifyContent: 'center' },
                dimensions.width
              )}
            >
              {/* show_direct */}
              <>
                {!Constants['ShoppingCart_direct']?.length ? null : (
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setShow_direct(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: 'transparent',
                        borderRadius: 8,
                        borderWidth: 1,
                        fontFamily: 'System',
                        fontWeight: '700',
                        marginTop: 10,
                        maxWidth: '60%',
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={`Voir les ${Constants['ShoppingCart_direct']?.length} commandes en attente auprès des fermes`}
                  />
                )}
              </>
            </View>
          )}
        </>
        {/* livraison_direct */}
        <>
          {!show_direct ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', justifyContent: 'center' },
                dimensions.width
              )}
            >
              {/* show_EP */}
              <>
                {!Constants['ShoppingCart']?.length ? null : (
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setShow_direct(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: 'transparent',
                        borderRadius: 8,
                        borderWidth: 1,
                        fontFamily: 'System',
                        fontWeight: '700',
                        marginTop: 10,
                        maxWidth: '60%',
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={`Voir la commande de ${Constants['ShoppingCart_direct']?.length} produit(s) en attente auprès de l'Epicerie Paysanne`}
                  />
                )}
              </>
            </View>
          )}
        </>
      </View>
      {/* View_EP */}
      <>
        {show_direct ? null : (
          <View>
            {/* Remarque */}
            <View>
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.text.strong,
                    fontSize: 16,
                    marginBottom: 10,
                  },
                  dimensions.width
                )}
              >
                {'Une remarque générale sur la commande ?'}
              </Text>
              {/* remarque_générale */}
              <TextInput
                autoCorrect={true}
                changeTextDelay={500}
                multiline={true}
                onChangeText={newRemarqueGNRaleValue => {
                  try {
                    setGlobalVariableValue({
                      key: 'remarque',
                      value: newRemarqueGNRaleValue,
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                textAlignVertical={'top'}
                webShowOutline={true}
                numberOfLines={3}
                placeholder={
                  "Indication particulière de livraison ? Produit que vous cherchez sans l'avoir vu ? un retour à nous transmettre ?" ??
                  'Indication particulière de livraison ?'
                }
                placeholderTextColor={theme.colors.text.light}
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: theme.colors.border.brand,
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                  },
                  dimensions.width
                )}
              />
            </View>

            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { fontSize: 16, marginBottom: 10, marginTop: 20 }
                ),
                dimensions.width
              )}
            >
              {'Votre panier'}
            </Text>
            {/* Panier */}
            <ScrollView
              bounces={true}
              horizontal={false}
              showsVerticalScrollIndicator={true}
              showsHorizontalScrollIndicator={false}
              style={StyleSheet.applyWidth({ flexGrow: 1 }, dimensions.width)}
            >
              <FlatList
                data={Constants['ShoppingCart']}
                horizontal={false}
                inverted={false}
                keyExtractor={(listData, index) =>
                  listData?.id ??
                  listData?.uuid ??
                  index?.toString() ??
                  JSON.stringify(listData)
                }
                keyboardShouldPersistTaps={'never'}
                listKey={'LqzhHEFi'}
                nestedScrollEnabled={false}
                numColumns={1}
                onEndReachedThreshold={0.5}
                renderItem={({ item, index }) => {
                  const listData = item;
                  return (
                    <View>
                      {/* CartItem */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginLeft: 32,
                            marginRight: 32,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Product_info */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              width: '95%',
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row', width: '100%' },
                              dimensions.width
                            )}
                          >
                            {/* Product_name */}
                            <View
                              style={StyleSheet.applyWidth(
                                { width: '65%' },
                                dimensions.width
                              )}
                            >
                              {/* Produit */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    alignSelf: 'flex-start',
                                    color: theme.colors.text.strong,
                                  },
                                  dimensions.width
                                )}
                              >
                                {extractProduct_name(listData)}
                              </Text>
                            </View>
                            {/* Product_inputs */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  flexDirection: 'row',
                                  width: '35%',
                                },
                                dimensions.width
                              )}
                            >
                              {/* qte */}
                              <>
                                {!ShowQte(listData) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { justifyContent: 'center' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Quantite */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.text.strong,
                                          flex: 1,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {extractProduct_qte(listData)}{' '}
                                      {extractProduct_unit(listData)}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* piece */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { justifyContent: 'center' },
                                  dimensions.width
                                )}
                              >
                                {/* Piece */}
                                <>
                                  {!ShowQty_piece(listData) ? null : (
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        { color: theme.colors.text.strong },
                                        dimensions.width
                                      )}
                                    >
                                      {extractProduct_piece(listData)}
                                      {' pièces'}
                                    </Text>
                                  )}
                                </>
                              </View>
                            </View>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                alignSelf: 'flex-start',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Product_precommande */}
                            <>
                              {!listData?.precommande ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.text.strong,
                                      fontSize: 12,
                                      textAlign: 'left',
                                      textDecorationLine: 'underline',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'En précommande'}
                                </Text>
                              )}
                            </>
                            {/* Product_notes */}
                            <>
                              {!listData?.notecommande ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.text.strong,
                                      fontSize: 12,
                                      textAlign: 'left',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'⚠ '}
                                  {listData?.notecommande}
                                </Text>
                              )}
                            </>
                          </View>
                        </View>
                        {/* Delete */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignContent: 'flex-end',
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              minWidth: 15,
                              width: '5%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Supprimer */}
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              try {
                                const newShoppingCartafterDeletion =
                                  deleteCartItem(extractProduct_id(listData));
                                setGlobalVariableValue({
                                  key: 'ShoppingCart',
                                  value: newShoppingCartafterDeletion,
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            icon={'Feather/trash'}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors.background.brand,
                                color: theme.colors.background.danger,
                                height: 15,
                                textAlign: 'center',
                                width: 15,
                              },
                              dimensions.width
                            )}
                            title={''}
                          />
                        </View>
                      </View>
                      <Divider
                        color={theme.colors.border.brand}
                        style={StyleSheet.applyWidth(
                          {
                            height: 2,
                            marginBottom: 4,
                            marginLeft: 10,
                            marginRight: 10,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                    </View>
                  );
                }}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={false}
              />
            </ScrollView>

            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end' },
                dimensions.width
              )}
            >
              {/* EmptyCart */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'ShoppingCart',
                      value: [],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors.background.brand,
                    borderBottomWidth: 2,
                    borderColor: theme.colors.background.danger,
                    borderLeftWidth: 2,
                    borderRadius: 8,
                    borderRightWidth: 2,
                    borderTopWidth: 2,
                    color: theme.colors.background.danger,
                    fontFamily: 'System',
                    fontSize: 12,
                    fontWeight: '600',
                    marginBottom: 15,
                    marginTop: 5,
                    textAlign: 'center',
                    width: '30%',
                  },
                  dimensions.width
                )}
                title={'Vider le Panier'}
              />
            </View>
          </View>
        )}
      </>
      {/* View_direct */}
      <>
        {!show_direct ? null : (
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* List_order_collecte */}
            <FlatList
              data={Constants['ShoppingCart_direct']}
              horizontal={false}
              inverted={false}
              keyExtractor={(listOrderCollecteData, index) =>
                listOrderCollecteData?.id ??
                listOrderCollecteData?.uuid ??
                index?.toString() ??
                JSON.stringify(listOrderCollecteData)
              }
              keyboardShouldPersistTaps={'never'}
              listKey={'RL24mrMM'}
              nestedScrollEnabled={false}
              numColumns={1}
              onEndReachedThreshold={0.5}
              renderItem={({ item, index }) => {
                const listOrderCollecteData = item;
                return (
                  <AccordionGroup
                    caretSize={24}
                    iconSize={24}
                    expanded={true}
                    label={listOrderCollecteData?.libelle}
                    style={StyleSheet.applyWidth(
                      {
                        fontFamily: 'System',
                        fontSize: 20,
                        fontWeight: '600',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        textDecorationLine: 'underline',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      {/* EmptyCart */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            const newShoppingCart_direct =
                              delete_order_collecte(
                                listOrderCollecteData?.collecte_id
                              );
                            setGlobalVariableValue({
                              key: 'ShoppingCart_direct',
                              value: newShoppingCart_direct,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors.background.brand,
                            borderBottomWidth: 2,
                            borderColor: theme.colors.background.danger,
                            borderLeftWidth: 2,
                            borderRadius: 8,
                            borderRightWidth: 2,
                            borderTopWidth: 2,
                            color: theme.colors.background.danger,
                            fontFamily: 'System',
                            fontSize: 12,
                            fontWeight: '600',
                            textAlign: 'center',
                            width: '20%',
                          },
                          dimensions.width
                        )}
                        title={'Vider ce Panier'}
                      />
                    </View>
                    {/* Panier */}
                    <ScrollView
                      bounces={true}
                      horizontal={false}
                      showsVerticalScrollIndicator={true}
                      showsHorizontalScrollIndicator={false}
                      style={StyleSheet.applyWidth(
                        { flexGrow: 1 },
                        dimensions.width
                      )}
                    >
                      {/* List_cart */}
                      <FlatList
                        data={listOrderCollecteData?.cart}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listCartData, index) =>
                          listCartData?.id ??
                          listCartData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listCartData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={JSON.stringify(listOrderCollecteData?.cart)}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listCartData = item;
                          return (
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingLeft: 10 },
                                dimensions.width
                              )}
                            >
                              {/* CartItem */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Product_info */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      width: '90%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row', width: '100%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Product_name */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { width: '65%' },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Produit */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignSelf: 'flex-start',
                                            color: theme.colors.text.strong,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {listCartData?.productname}
                                      </Text>
                                    </View>
                                    {/* Product_inputs */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignContent: 'center',
                                          alignItems: 'center',
                                          alignSelf: 'center',
                                          flexDirection: 'row',
                                          width: '35%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* qte */}
                                      <>
                                        {!ShowQte(listCartData) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { justifyContent: 'center' },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Quantite */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors.text.strong,
                                                  flex: 1,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {extractProduct_qte(listCartData)}{' '}
                                              {extractProduct_unit(
                                                listCartData
                                              )}
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                      {/* piece */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { justifyContent: 'center' },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Piece */}
                                        <>
                                          {!ShowQty_piece(
                                            listCartData
                                          ) ? null : (
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors.text.strong,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {extractProduct_piece(
                                                listCartData
                                              )}
                                              {' pièces'}
                                            </Text>
                                          )}
                                        </>
                                      </View>
                                    </View>
                                  </View>

                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'flex-start',
                                        alignSelf: 'flex-start',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Product_precommande */}
                                    <>
                                      {!listCartData?.precommande ? null : (
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors.text.strong,
                                              fontSize: 12,
                                              textAlign: 'left',
                                              textDecorationLine: 'underline',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'En précommande'}
                                        </Text>
                                      )}
                                    </>
                                    {/* Product_notes */}
                                    <>
                                      {!listCartData?.notecommande ? null : (
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors.text.strong,
                                              fontSize: 12,
                                              textAlign: 'left',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'⚠ '}
                                          {listCartData?.notecommande}
                                        </Text>
                                      )}
                                    </>
                                  </View>
                                </View>
                                {/* Delete */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignContent: 'flex-end',
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      justifyContent: 'flex-end',
                                      minWidth: 15,
                                      width: '5%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Supprimer */}
                                  <Button
                                    iconPosition={'left'}
                                    onPress={() => {
                                      try {
                                        const newShoppingDirectCartafterDeletion =
                                          delete_direct_cartItem(
                                            listCartData?.productId,
                                            listOrderCollecteData?.collecte_id
                                          );
                                        setGlobalVariableValue({
                                          key: 'ShoppingCart_direct',
                                          value:
                                            newShoppingDirectCartafterDeletion,
                                        });
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    icon={'Feather/trash'}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          theme.colors.background.brand,
                                        color: theme.colors.background.danger,
                                        height: 15,
                                        textAlign: 'center',
                                        width: 15,
                                      },
                                      dimensions.width
                                    )}
                                    title={''}
                                  />
                                </View>
                              </View>
                              <Divider
                                color={theme.colors.border.brand}
                                style={StyleSheet.applyWidth(
                                  {
                                    height: 1,
                                    marginBottom: 4,
                                    marginLeft: 10,
                                    marginRight: 10,
                                    marginTop: 4,
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                        contentContainerStyle={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                      />
                    </ScrollView>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          marginBottom: 10,
                          marginTop: 10,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* ConfirmOrder */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              (
                                await makeOrderCollecteDirectPOST.mutateAsync({
                                  cart: listOrderCollecteData?.cart,
                                  collecte_id:
                                    listOrderCollecteData?.collecte_id,
                                  customeremail: Constants['emailclient'],
                                  ferme: listOrderCollecteData?.ferme,
                                  remarque: 'none',
                                })
                              )?.json;
                              const newShoppingCart_direct =
                                delete_order_collecte(
                                  listOrderCollecteData?.collecte_id
                                );
                              setGlobalVariableValue({
                                key: 'ShoppingCart_direct',
                                value: newShoppingCart_direct,
                              });
                              navigation.navigate('BottomTabNavigator', {
                                screen: 'Commande',
                                params: { screen: 'CommandeconfirmeScreen' },
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors.branding.primary,
                            borderRadius: 8,
                            fontFamily: 'System',
                            fontWeight: '700',
                            textAlign: 'center',
                            width: '50%',
                          },
                          dimensions.width
                        )}
                        title={`Confirmer
${listOrderCollecteData?.ferme}`}
                      />
                    </View>
                  </AccordionGroup>
                );
              }}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
              contentContainerStyle={StyleSheet.applyWidth(
                { flex: 1 },
                dimensions.width
              )}
            />
          </View>
        )}
      </>
      {/* CTA */}
      <View
        style={StyleSheet.applyWidth(
          {
            bottom: 0,
            flexDirection: 'row',
            height: 60,
            justifyContent: 'space-between',
            left: 20,
            marginBottom: 20,
            paddingBottom: 20,
            paddingTop: 20,
            position: 'absolute',
            right: 20,
            zIndex: 0,
          },
          dimensions.width
        )}
      >
        {/* Back_direct */}
        <>
          {!show_direct ? null : (
            <Button
              iconPosition={'left'}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.background.brand,
                  borderBottomWidth: 2,
                  borderColor: theme.colors.branding.primary,
                  borderLeftWidth: 2,
                  borderRadius: 8,
                  borderRightWidth: 2,
                  borderTopWidth: 2,
                  color: theme.colors.branding.primary,
                  fontFamily: 'System',
                  fontSize: 14,
                  fontWeight: '600',
                  textAlign: 'center',
                },
                dimensions.width
              )}
              title={"Revenir à l'arrivage"}
            />
          )}
        </>
        {/* Back_EP */}
        <>
          {show_direct ? null : (
            <Button
              iconPosition={'left'}
              onPress={() => {
                try {
                  const updatedShoppingCart = navigation.navigate(
                    'BottomTabNavigator',
                    {
                      screen: 'Commande',
                      params: { screen: 'CatalogueScreen' },
                    }
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.background.brand,
                  borderBottomWidth: 2,
                  borderColor: theme.colors.branding.primary,
                  borderLeftWidth: 2,
                  borderRadius: 8,
                  borderRightWidth: 2,
                  borderTopWidth: 2,
                  color: theme.colors.branding.primary,
                  fontFamily: 'System',
                  fontSize: 14,
                  fontWeight: '600',
                  textAlign: 'center',
                },
                dimensions.width
              )}
              title={"Revenir à l'arrivage"}
            />
          )}
        </>
        <>
          {show_direct ? null : (
            <View>
              {/* ConfirmOrder_EP */}
              <>
                {!Constants['emailclient'] ? null : (
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          (
                            await makeOrderCreationPOST.mutateAsync({
                              customeremail: Constants['emailclient'],
                              day: getDate_Day(Constants['deliverydate']),
                              month: getDate_Month(Constants['deliverydate']),
                              remarque: Constants['remarque'],
                              shoppingcart: Constants['ShoppingCart'],
                              year: getDate_Year(Constants['deliverydate']),
                            })
                          )?.json;
                          navigation.navigate('BottomTabNavigator', {
                            screen: 'Commande',
                            params: { screen: 'CommandeconfirmeScreen' },
                          });
                          setGlobalVariableValue({
                            key: 'ShoppingCart',
                            value: '',
                          });
                          setGlobalVariableValue({
                            key: 'remarque',
                            value: '',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.branding.primary,
                        fontFamily: 'System',
                        fontSize: 14,
                        fontWeight: '700',
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'Confirmer la commande'}
                  />
                )}
              </>
            </View>
          )}
        </>
        {/* FillEmailAdress */}
        <>
          {Constants['emailclient'] ? null : (
            <Button
              iconPosition={'left'}
              onPress={() => {
                try {
                  navigation.navigate('Auth');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.branding.primary,
                  fontFamily: 'System',
                  fontSize: 14,
                  fontWeight: '700',
                  textAlign: 'center',
                },
                dimensions.width
              )}
              title={'Renseigner votre email'}
            />
          )}
        </>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(PanierScreen);
