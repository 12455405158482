import React from 'react';
import {
  AccordionGroup,
  Button,
  Divider,
  Picker,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as CustomCode from '../CustomCode';
import * as NinoxApi from '../apis/NinoxApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const M1MarchScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [pickerValue, setPickerValue] = React.useState('');
  const [pickerValue2, setPickerValue2] = React.useState('');
  const [show_direct, setShow_direct] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const getDateDay = date => {
    const datePicker = new Date(date);
    const datePicker_day = datePicker.getDate();
    return datePicker_day;
  };

  const setDeliveryDate_today = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    return tomorrow.setDate(tomorrow.getDate() + 1);
  };

  const getDateYear = date => {
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.
    const datePicker = new Date(date);
    const datePicker_year = datePicker.getFullYear();
    return datePicker_year;
    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
  };

  const getDateMonth = date => {
    const datePicker = new Date(date);
    const datePicker_month = datePicker.getMonth() + 1;
    return datePicker_month;
  };

  const LivraisonRemplie = datePicker => {
    let date = datePicker;
    let response = date > 0 ? false : true;
    return response;
  };

  const filterList = list => {
    let lowerfiltertext = textInputValue.toLowerCase();
    let newList = list.filter(item =>
      item.label.toLowerCase().includes(lowerfiltertext)
    );
    return newList;
  };

  const getDateString = deliverydate => {
    const datetoformat = new Date(deliverydate);
    const options = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const datestring = datetoformat.toLocaleDateString('fr-FR', options);

    return datestring;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      console.log('Hi! This is a test!');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { flex: 1, marginLeft: 20, marginRight: 20 },
        dimensions.width
      )}
    >
      <Modal
        animationType={'none'}
        supportedOrientations={['portrait', 'landscape']}
        transparent={false}
        visible={show_direct}
      />
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 10,
            marginLeft: 20,
            marginRight: 20,
            marginTop: 10,
          },
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          selectable={false}
          style={StyleSheet.applyWidth(
            {
              alignSelf: 'flex-start',
              color: theme.colors.text.strong,
              fontFamily: 'PTSerif_400Regular_Italic',
              fontSize: 25,
            },
            dimensions.width
          )}
        >
          {'Le Marché en Direct'}
        </Text>
        <Image
          resizeMode={'cover'}
          source={imageSource(Images['CarotteDetouree'])}
          style={StyleSheet.applyWidth(
            { height: 60, width: 60 },
            dimensions.width
          )}
        />
      </View>
      {/* Filtres */}
      <AccordionGroup
        caretSize={24}
        iconSize={24}
        icon={'Feather/filter'}
        label={'Filtrer les mercuriales'}
        style={StyleSheet.applyWidth(
          { flexDirection: 'row', fontSize: 14 },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'flex-end',
              alignItems: 'flex-end',
              alignSelf: 'flex-end',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            },
            dimensions.width
          )}
        >
          <NinoxApi.FetchFermierDirectGET zone_id={1}>
            {({ loading, error, data, refetchFermierDirect }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Ferme */}
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.brand}
                    dropDownBorderColor={theme.colors.border.brand}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    mode={'native'}
                    onValueChange={newFermeValue => {
                      try {
                        setPickerValue2(newFermeValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    label={'Filtrer par ferme'}
                    options={fetchData}
                    placeholder={'Filtrer par ferme'}
                    placeholderTextColor={theme.colors.text.light}
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 2,
                        borderColor: theme.colors.text.light,
                        color: theme.colors.text.strong,
                        fontSize: 14,
                        textAlign: 'left',
                      },
                      dimensions.width
                    )}
                    type={'underline'}
                    value={pickerValue2}
                  />
                </>
              );
            }}
          </NinoxApi.FetchFermierDirectGET>
        </View>
        {/* filter */}
        <TextInput
          autoCapitalize={'none'}
          autoCorrect={true}
          changeTextDelay={500}
          onChangeText={newFilterValue => {
            try {
              setTextInputValue(newFilterValue);
            } catch (err) {
              console.error(err);
            }
          }}
          webShowOutline={true}
          placeholder={'Rechercher un produit' ?? 'Rechercher'}
          style={StyleSheet.applyWidth(
            {
              borderBottomWidth: 1,
              borderColor: theme.colors.border.brand,
              borderLeftWidth: 1,
              borderRadius: 8,
              borderRightWidth: 1,
              borderTopWidth: 1,
              marginTop: 10,
              paddingBottom: 8,
              paddingLeft: 8,
              paddingRight: 8,
              paddingTop: 8,
            },
            dimensions.width
          )}
          value={textInputValue}
        />
      </AccordionGroup>
      <Divider
        color={theme.colors.border.brand}
        style={StyleSheet.applyWidth(
          { height: 2, marginBottom: 15, marginTop: 15 },
          dimensions.width
        )}
      />
      <ScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
      >
        <NinoxApi.FetchCollecteDirectGET
          handlers={{
            onData: fetchData => {
              try {
                console.log(fetchData);
              } catch (err) {
                console.error(err);
              }
            },
          }}
          zone_id={Constants['zone']}
        >
          {({ loading, error, data, refetchCollecteDirect }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <FlatList
                data={fetchData}
                horizontal={false}
                inverted={false}
                keyExtractor={(listData, index) =>
                  listData?.id ??
                  listData?.uuid ??
                  index?.toString() ??
                  JSON.stringify(listData)
                }
                keyboardShouldPersistTaps={'never'}
                listKey={'lcC3arAZ'}
                nestedScrollEnabled={false}
                numColumns={1}
                onEndReachedThreshold={0.5}
                renderItem={({ item, index }) => {
                  const listData = item;
                  return (
                    <AccordionGroup
                      caretSize={24}
                      iconSize={24}
                      expanded={true}
                      label={listData?.label_order_collecte}
                      style={StyleSheet.applyWidth(
                        { fontSize: 16, marginBottom: 10 },
                        dimensions.width
                      )}
                    >
                      <>
                        {!listData?.franco ? null : (
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.text.strong,
                                marginBottom: 10,
                                textDecorationLine: 'underline',
                              },
                              dimensions.width
                            )}
                          >
                            {'Franco de '}
                            {listData?.franco}
                            {'€ avec cette ferme'}
                          </Text>
                        )}
                      </>
                      <FlatList
                        data={filterList(listData?.produits)}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={JSON.stringify(filterList(listData?.produits))}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* Product */}
                              <Touchable
                                onPress={() => {
                                  try {
                                    navigation.navigate('LeMarch');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  { maxWidth: '100%' },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'flex-start',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      paddingBottom: 8,
                                      paddingLeft: 10,
                                      paddingTop: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Libellé */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { alignSelf: 'stretch', maxWidth: '80%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* product_name */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      numberOfLines={2}
                                      style={StyleSheet.applyWidth(
                                        {
                                          fontFamily: 'System',
                                          fontSize: 14,
                                          fontWeight: '600',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.label}
                                    </Text>

                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flexDirection: 'row' },
                                        dimensions.width
                                      )}
                                    >
                                      {/* product_unit */}
                                      <>
                                        {listData?.cond ? null : (
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.text.strong,
                                                fontFamily: 'System',
                                                fontSize: 12,
                                                fontStyle: 'italic',
                                                fontWeight: '400',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'par '}
                                            {listData?.unit}
                                          </Text>
                                        )}
                                      </>
                                      {/* product_conditionning */}
                                      <>
                                        {!listData?.cond ? null : (
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.text.strong,
                                                fontFamily: 'System',
                                                fontSize: 12,
                                                fontStyle: 'italic',
                                                fontWeight: '400',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'conditionné par '}
                                            {listData?.cond}
                                          </Text>
                                        )}
                                      </>
                                    </View>
                                  </View>
                                  {/* Prix */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'flex-end',
                                        justifyContent: 'flex-end',
                                        minWidth: 40,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        { fontSize: 15 },
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.prix_total}
                                      {' €'}
                                    </Text>

                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.text.strong,
                                          fontSize: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'/ '}
                                      {listData?.unit}
                                    </Text>
                                  </View>
                                </View>
                                <Divider
                                  color={theme.colors.border.brand}
                                  style={StyleSheet.applyWidth(
                                    { height: 1, marginLeft: 10 },
                                    dimensions.width
                                  )}
                                />
                              </Touchable>
                            </>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                        contentContainerStyle={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                      />
                    </AccordionGroup>
                  );
                }}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              />
            );
          }}
        </NinoxApi.FetchCollecteDirectGET>
      </ScrollView>

      <View
        style={StyleSheet.applyWidth(
          {
            bottom: 0,
            height: 60,
            justifyContent: 'center',
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
          },
          dimensions.width
        )}
      >
        {/* CartPreview */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'PanierScreen',
                params: { direct_view: true },
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.branding.primary,
              fontFamily: 'System',
              fontWeight: '700',
              height: 35,
              textAlign: 'center',
            },
            dimensions.width
          )}
          title={'Aperçu de la commande'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(M1MarchScreen);
