import React from 'react';
import {
  Button,
  NumberInput,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Spacer,
  TextField,
  withTheme,
} from '@draftbit/ui';
import { Platform, Text, View } from 'react-native';
import * as CustomCode from '../CustomCode';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  arrivage_ID: '',
  collecte_id: '',
  com: '',
  conditionning: '',
  deliverytimestring: '',
  ferme: '',
  label: '',
  label_order_collecte: '',
  prix_ferme: '',
  prix_total: '',
  productID: '',
  unit: '',
};

const M2ProduitScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [piece, setPiece] = React.useState('');
  const [quantite, setQuantite] = React.useState('');
  const [remarque, setRemarque] = React.useState('');
  const ShowUnit = unit => {
    let response = unit == 'unité' ? false : true;
    return response;
  };

  const addToCart_direct = (
    quantite,
    pieces,
    remarque,
    productID,
    name,
    unit,
    collecte_id,
    ferme,
    deliverytime,
    libelle_order_collecte,
    arrivageID
  ) => {
    // declare variables
    let newShoppingCart_direct = Constants.ShoppingCart_direct;
    let collecte_order = {};
    let cartItem = {
      productId: productID,
      productname: name,
      unit: unit,
      qty: quantite,
      qty_piece: pieces,
      arrivageID: arrivageID,
      remarque: remarque,
    };
    const collecte_order_to_update = newShoppingCart_direct.filter(
      item => item.collecte_id === collecte_id
    )[0];

    // find if the order_collecte already exists to add current product to its cart
    if (
      newShoppingCart_direct.filter(item => item.collecte_id === collecte_id)
        .length > 0
    ) {
      // set a newly updated cart for this specific order_collecte
      let newCart = collecte_order_to_update.cart.map(item => item);
      newCart.push(cartItem);

      // update the cart of the specific order_collecte
      let filterResult = newShoppingCart_direct.filter(
        item => item.collecte_id === collecte_id
      );
      filterResult[0].cart = newCart;
    } else {
      // create a new order_collecte with the cartItem
      let collecte_order = {
        collecte_id: collecte_id,
        libelle: libelle_order_collecte,
        ferme: ferme,
        deliverytime: deliverytime,
        remarque: '',
        cart: [cartItem],
      };
      newShoppingCart_direct.push(collecte_order);
    }
    return newShoppingCart_direct;
  };

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        { justifyContent: 'space-around' },
        dimensions.width
      )}
    >
      <SimpleStyleKeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
      >
        <View
          style={StyleSheet.applyWidth(
            { alignSelf: 'center', marginTop: 16, width: '90%' },
            dimensions.width
          )}
        >
          {/* Product_name */}
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                color: theme.colors.text.strong,
                fontFamily: 'System',
                fontSize: 30,
                fontWeight: '600',
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {props.route?.params?.label ?? defaultProps.label}
          </Text>
          {/* Product_conditionning */}
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              { alignSelf: 'center', color: theme.colors.text.strong },
              dimensions.width
            )}
          >
            {props.route?.params?.conditionning ?? defaultProps.conditionning}
          </Text>
          <Spacer bottom={8} left={8} right={8} top={8} />
          {/* Pricing_total */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'flex-end',
                alignItems: 'flex-end',
                flexDirection: 'row',
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'flex-end',
                  alignSelf: 'flex-end',
                  justifyContent: 'flex-end',
                  marginRight: 2,
                  width: '50%',
                },
                dimensions.width
              )}
            >
              {/* Pricing_amount */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.text.strong,
                    fontSize: 24,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {props.route?.params?.prix_total ?? defaultProps.prix_total}
                {'€'}
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'flex-start',
                  justifyContent: 'flex-end',
                  marginLeft: 2,
                  width: '50%',
                },
                dimensions.width
              )}
            >
              {/* Pricing_unit */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  { color: theme.colors.text.strong },
                  dimensions.width
                )}
              >
                {'HT / '}
                {props.route?.params?.unit ?? defaultProps.unit}
              </Text>
            </View>
          </View>
          {/* Pricing_details */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'flex-end',
                alignItems: 'flex-end',
                flexDirection: 'row',
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            {/* Pricing_amount */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontFamily: 'System',
                  fontSize: 14,
                  fontStyle: 'italic',
                  fontWeight: '300',
                  textAlign: 'center',
                },
                dimensions.width
              )}
            >
              {'dont '}
              {props.route?.params?.prix_ferme ?? defaultProps.prix_ferme}
              {'€ pour la ferme'}
            </Text>
          </View>
        </View>
        <Spacer bottom={8} left={8} right={8} top={8} />
        <View
          style={StyleSheet.applyWidth(
            { alignSelf: 'center', width: '90%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-between',
                maxWidth: '100%',
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth({ width: '45%' }, dimensions.width)}
            >
              <View>
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.text.strong,
                      fontFamily: 'System',
                      fontWeight: '600',
                    },
                    dimensions.width
                  )}
                >
                  {'Combien de '}
                  {props.route?.params?.unit ?? defaultProps.unit}
                  {' ?'}
                </Text>
                <>
                  {!(
                    props.route?.params?.conditionning ??
                    defaultProps.conditionning
                  ) ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.text.strong,
                          fontFamily: 'System',
                          fontWeight: '400',
                        },
                        dimensions.width
                      )}
                    >
                      {'('}
                      {props.route?.params?.conditionning ??
                        defaultProps.conditionning}
                      {')'}
                    </Text>
                  )}
                </>
              </View>
              {/* unite_android */}
              <>
                {Platform.OS === 'ios' ? null : (
                  <NumberInput
                    changeTextDelay={500}
                    onChangeText={newUniteAndroidValue => {
                      try {
                        setQuantite(newUniteAndroidValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    clearTextOnFocus={true}
                    keyboardType={'numeric'}
                    placeholder={'Renseigner la quantité'}
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: theme.colors.border.brand,
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                    value={quantite}
                  />
                )}
              </>
              {/* unite_iOS */}
              <>
                {!(Platform.OS === 'ios') ? null : (
                  <NumberInput
                    changeTextDelay={500}
                    onChangeText={newUniteIOSValue => {
                      try {
                        setQuantite(newUniteIOSValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    clearTextOnFocus={true}
                    keyboardType={'numbers-and-punctuation'}
                    placeholder={'Renseigner la quantité'}
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: theme.colors.border.brand,
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                  />
                )}
              </>
            </View>
            <>
              {!ShowUnit(
                props.route?.params?.unit ?? defaultProps.unit
              ) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { width: '45%' },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'System',
                        fontWeight: '600',
                      },
                      dimensions.width
                    )}
                  >
                    {'Combien de pièces ?'}
                  </Text>
                  {/* a la piece */}
                  <NumberInput
                    changeTextDelay={500}
                    onChangeText={newALaPieceValue => {
                      try {
                        setPiece(newALaPieceValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    clearTextOnFocus={true}
                    keyboardType={'numbers-and-punctuation'}
                    placeholder={'Renseigner la quantité désirée'}
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: theme.colors.border.brand,
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                    value={piece}
                  />
                </View>
              )}
            </>
          </View>
          <Spacer left={8} right={8} bottom={16} top={16} />
          <View>
            {/* Remarque */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontFamily: 'System',
                  fontWeight: '600',
                },
                dimensions.width
              )}
            >
              {'Aucune remarque ? Calibre spécifique ?'}
            </Text>
            {/* Sous-remarque */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontFamily: 'System',
                  fontSize: 10,
                  fontStyle: 'italic',
                  fontWeight: '300',
                },
                dimensions.width
              )}
            >
              {
                'Mentionner également si cette volonté est "sine qua non", à savoir si nous n\'y arrivons vous préférez ne pas être livré du tout'
              }
            </Text>
            {/* remarque */}
            <TextField
              activeBorderColor={theme.colors.branding.primary}
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newRemarqueValue => {
                try {
                  setRemarque(newRemarqueValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              type={'solid'}
              underlineColor={theme.colors.text.light}
              webShowOutline={true}
              allowFontScaling={false}
              placeholder={'Remarque'}
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.border.brand,
                  borderRadius: 8,
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                  textAlign: 'left',
                },
                dimensions.width
              )}
              value={remarque}
            />
          </View>
        </View>
      </SimpleStyleKeyboardAwareScrollView>

      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: 'center',
            bottom: 0,
            flexDirection: 'row',
            height: 60,
            justifyContent: 'space-around',
            width: '90%',
          },
          dimensions.width
        )}
      >
        {/* AddToCart_direct */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            console.log('AddToCart_direct ON_PRESS Start');
            let error = null;
            try {
              console.log('Start ON_PRESS:0 CUSTOM_FUNCTION');
              const updatedCart = addToCart_direct(
                quantite,
                piece,
                remarque,
                props.route?.params?.productID ?? defaultProps.productID,
                props.route?.params?.label ?? defaultProps.label,
                props.route?.params?.unit ?? defaultProps.unit,
                props.route?.params?.collecte_id ?? defaultProps.collecte_id,
                props.route?.params?.ferme ?? defaultProps.ferme,
                props.route?.params?.deliverytimestring ??
                  defaultProps.deliverytimestring,
                props.route?.params?.label_order_collecte ??
                  defaultProps.label_order_collecte,
                props.route?.params?.arrivage_ID ?? defaultProps.arrivage_ID,
                undefined
              );
              console.log('Complete ON_PRESS:0 CUSTOM_FUNCTION', {
                updatedCart,
              });
              console.log('Start ON_PRESS:1 SET_VARIABLE');
              setGlobalVariableValue({
                key: 'ShoppingCart_direct',
                value: updatedCart,
              });
              console.log('Complete ON_PRESS:1 SET_VARIABLE');
              console.log('Start ON_PRESS:3 SET_VARIABLE');
              setQuantite('');
              console.log('Complete ON_PRESS:3 SET_VARIABLE');
              console.log('Start ON_PRESS:4 SET_VARIABLE');
              setPiece('');
              console.log('Complete ON_PRESS:4 SET_VARIABLE');
              console.log('Start ON_PRESS:5 SET_VARIABLE');
              setRemarque('');
              console.log('Complete ON_PRESS:5 SET_VARIABLE');
              console.log('Start ON_PRESS:6 NAVIGATE');
              navigation.navigate('LeMarch');
              console.log('Complete ON_PRESS:6 NAVIGATE');
            } catch (err) {
              console.error(err);
              error = err.message ?? err;
            }
            console.log(
              'AddToCart_direct ON_PRESS Complete',
              error ? { error } : 'no error'
            );
          }}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.branding.primary,
              fontFamily: 'System',
              fontWeight: '700',
              height: 35,
              textAlign: 'center',
              width: '40%',
            },
            dimensions.width
          )}
          title={'Commander'}
        />
        {/* Cancel */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            try {
              navigation.navigate('LeMarch');
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.background.brand,
              borderBottomWidth: 2,
              borderColor: theme.colors.background.danger,
              borderLeftWidth: 2,
              borderRadius: 8,
              borderRightWidth: 2,
              borderTopWidth: 2,
              color: theme.colors.background.danger,
              fontFamily: 'System',
              fontWeight: '700',
              height: 35,
              textAlign: 'center',
              width: '40%',
            },
            dimensions.width
          )}
          title={'Annuler'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(M2ProduitScreen);
