import React from 'react';
import { Icon, ScreenContainer, Touchable, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as DraftbitExampleApi from '../apis/DraftbitExampleApi.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const HH1HistoriqueScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            height: 48,
            marginBottom: 10,
            marginTop: 12,
            paddingLeft: 20,
            paddingRight: 20,
          },
          dimensions.width
        )}
      >
        {/* Screen Heading */}
        <Text
          accessible={true}
          selectable={false}
          style={StyleSheet.applyWidth(
            {
              color: theme.colors.text.strong,
              fontFamily: 'Inter_500Medium',
              fontSize: 24,
            },
            dimensions.width
          )}
        >
          {'Commandes'}
        </Text>
      </View>
      {/* Transactions */}
      <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
        <DraftbitExampleApi.FetchDoctorsListGET count={6}>
          {({ loading, error, data, refetchDoctorsList }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <FlashList
                data={fetchData}
                estimatedItemSize={50}
                horizontal={false}
                inverted={false}
                keyExtractor={(flashListData, index) =>
                  flashListData?.id ??
                  flashListData?.uuid ??
                  index?.toString() ??
                  JSON.stringify(flashListData)
                }
                listKey={'4dpEcMmT'}
                numColumns={1}
                onEndReachedThreshold={0.5}
                renderItem={({ item, index }) => {
                  const flashListData = item;
                  return (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          backgroundColor: palettes.App['Custom #ffffff'],
                          borderRadius: 12,
                          flexDirection: 'row',
                          marginBottom: 20,
                          marginLeft: 20,
                          marginRight: 20,
                          paddingBottom: 20,
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 20,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                      >
                        {/* Title */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.text.strong,
                              fontFamily: 'Inter_500Medium',
                              fontSize: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {'Buy 2 Medicine Woods Papermint'}
                        </Text>
                        {/* type  */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.text.strong,
                              fontFamily: 'Inter_300Light',
                              fontSize: 12,
                              marginTop: 4,
                              opacity: 0.6,
                            },
                            dimensions.width
                          )}
                        >
                          {'Medicine • Monday, 4:19 PM'}
                        </Text>
                        {/* Status Tags */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'flex-start' },
                            dimensions.width
                          )}
                        >
                          {/* Pending Payment */}
                          <>
                            {!(flashListData?.id === 1) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      palettes.App['Custom Color_19'],
                                    borderRadius: 5,
                                    marginTop: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: palettes.App['Custom Color_4'],
                                      fontFamily: 'Inter_400Regular',
                                      fontSize: 12,
                                      paddingBottom: 6,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                      paddingTop: 6,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Pending Payment'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Not Confirmed Yet */}
                          <>
                            {!(flashListData?.id === 3) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      palettes.App['Custom Color_7'],
                                    borderRadius: 5,
                                    marginTop: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.text.strong,
                                      fontFamily: 'Inter_400Regular',
                                      fontSize: 12,
                                      paddingBottom: 6,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                      paddingTop: 6,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Not Confirmed Yet'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Complete */}
                          <>
                            {!(flashListData?.id === 2) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      palettes.App['Custom Color_21'],
                                    borderRadius: 5,
                                    marginTop: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: palettes.App['Custom Color_20'],
                                      fontFamily: 'Inter_400Regular',
                                      fontSize: 12,
                                      paddingBottom: 6,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                      paddingTop: 6,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Complete'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Confirm */}
                          <>
                            {!(flashListData?.id > 3) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      palettes.App['Custom Color_21'],
                                    borderRadius: 5,
                                    marginTop: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: palettes.App['Custom Color_20'],
                                      fontFamily: 'Inter_400Regular',
                                      fontSize: 12,
                                      paddingBottom: 6,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                      paddingTop: 6,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Confirm'}
                                </Text>
                              </View>
                            )}
                          </>
                        </View>
                      </View>
                    </View>
                  );
                }}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              />
            );
          }}
        </DraftbitExampleApi.FetchDoctorsListGET>
      </View>
      {/* Bottom Tab */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: palettes.App['Custom #ffffff'],
            borderTopLeftRadius: 32,
            borderTopRightRadius: 32,
            flexDirection: 'row',
            height: 117,
            justifyContent: 'space-between',
            paddingBottom: 20,
            paddingLeft: 30,
            paddingRight: 30,
          },
          dimensions.width
        )}
      >
        {/* Home */}
        <Touchable activeOpacity={0.8} disabledOpacity={0.8}>
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: 48,
                justifyContent: 'center',
                width: 48,
              },
              dimensions.width
            )}
          >
            <Icon
              size={24}
              color={theme.colors.text.medium}
              name={'AntDesign/home'}
            />
          </View>
        </Touchable>
        {/* History Transaction */}
        <Touchable activeOpacity={0.8} disabledOpacity={0.8}>
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: 48,
                justifyContent: 'center',
                width: 48,
              },
              dimensions.width
            )}
          >
            <Icon
              size={24}
              color={theme.colors.branding.primary}
              name={'Ionicons/document-text'}
            />
          </View>
        </Touchable>
        {/* Messages */}
        <Touchable activeOpacity={0.8} disabledOpacity={0.8}>
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: 48,
                justifyContent: 'center',
                width: 48,
              },
              dimensions.width
            )}
          >
            <Icon
              size={24}
              color={theme.colors.text.medium}
              name={'Ionicons/chatbox-outline'}
            />
          </View>
        </Touchable>
        {/* Profile */}
        <Touchable activeOpacity={0.8} disabledOpacity={0.8}>
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: 48,
                justifyContent: 'center',
                width: 48,
              },
              dimensions.width
            )}
          >
            <Icon
              size={24}
              color={theme.colors.text.medium}
              name={'AntDesign/user'}
            />
          </View>
        </Touchable>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(HH1HistoriqueScreen);
