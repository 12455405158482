export default {
  App: {
    custom_rgb138_132_125: 'rgb(138, 132, 125)',
    'Custom #8a847d': 'rgb(138, 132, 125)',
    custom_rgb8_73_185: 'rgb(8, 73, 185)',
    'Custom Color_8': 'rgb(8, 73, 185)',
    peoplebitDarkEmeraldGreen: 'rgb(20, 73, 92)',
    Peoplebit_Dark_Emerald_Green: 'rgb(20, 73, 92)',
    studilySlateBlueDark: 'rgb(56, 67, 84)',
    Studily_Slate_Blue_Dark: 'rgb(56, 67, 84)',
    tabDivider: 'rgb(231, 235, 241)',
    Tab_Divider: 'rgb(231, 235, 241)',
    custom_rgb0_55_75: 'rgb(0, 55, 75)',
    'Custom #00374b': 'rgb(0, 55, 75)',
    custom_rgb156_164_171: 'rgb(156, 164, 171)',
    'Custom Color_16': 'rgb(156, 164, 171)',
    custom_rgb216_216_216: 'rgb(216, 216, 216)',
    'Custom #d8d8d8': 'rgb(216, 216, 216)',
    textPlaceholder: 'rgb(136, 144, 153)',
    'text placeholder': 'rgb(136, 144, 153)',
    custom_rgb243_102_102: 'rgb(243, 102, 102)',
    'Custom Color_11': 'rgb(243, 102, 102)',
    custom_rgb253_253_253: 'rgb(253, 253, 253)',
    'Custom Color_15': 'rgb(253, 253, 253)',
    custom_rgb253_247_247: 'rgb(253, 247, 247)',
    'Custom Color_19': 'rgb(253, 247, 247)',
    custom_rgb255_0_0: 'rgb(255, 0, 0)',
    'Custom Color_4': 'rgb(255, 0, 0)',
    greenBG: 'rgba(0, 204, 154, 0.21)',
    'Green BG': 'rgba(0, 204, 154, 0.21)',
    peoplebitTurquoise: 'rgb(6, 48, 63)',
    Peoplebit_Turquoise: 'rgb(6, 48, 63)',
    shopAppBlue: 'rgb(14, 134, 212)',
    ShopAppBlue: 'rgb(14, 134, 212)',
    studilyWashedLavenderWhite: 'rgb(227, 231, 249)',
    Studily_Washed_Lavender_White: 'rgb(227, 231, 249)',
    studilyWhiteBG: 'rgb(243, 245, 249)',
    Studily_White_BG: 'rgb(243, 245, 249)',
    textPlaceholder: 'rgb(136, 144, 153)',
    TextPlaceholder: 'rgb(136, 144, 153)',
    custom_rgb128_128_128: 'rgb(128, 128, 128)',
    'Custom Color_3': 'rgb(128, 128, 128)',
    studilyGrayMachine: 'rgb(133, 141, 152)',
    Studily_Gray_Machine: 'rgb(133, 141, 152)',
    studilyLightBlue: 'rgb(55, 66, 83)',
    Studily_Light_Blue: 'rgb(55, 66, 83)',
    studilyMilkWhite: 'rgb(236, 240, 246)',
    Studily_Milk_White: 'rgb(236, 240, 246)',
    studilyPurpleLight: 'rgb(208, 215, 250)',
    Studily_Purple_Light: 'rgb(208, 215, 250)',
    studilySkyBluePrimary: 'rgb(86, 184, 244)',
    Studily_Sky_Blue_Primary: 'rgb(86, 184, 244)',
    viewBG: 'rgba(152, 153, 156, 0.25)',
    ViewBG: 'rgba(152, 153, 156, 0.25)',
    bGGray: 'rgba(225, 218, 218, 0.15)',
    'BG Gray': 'rgba(225, 218, 218, 0.15)',
    custom_rgb140_177_240: 'rgb(140, 177, 240)',
    'Custom Color_9': 'rgb(140, 177, 240)',
    peoplebitSalmonRed: 'rgb(211, 83, 109)',
    Peoplebit_Salmon_Red: 'rgb(211, 83, 109)',
    peoplebitWhite: 'rgb(255, 255, 255)',
    Peoplebit_White: 'rgb(255, 255, 255)',
    studilyLavenderPurple: 'rgb(208, 215, 250)',
    Studily_Lavender_Purple: 'rgb(208, 215, 250)',
    studilySnowWhite: 'rgb(230, 235, 244)',
    Studily_Snow_White: 'rgb(230, 235, 244)',
    studilyWhite: 'rgb(255, 255, 255)',
    Studily_White: 'rgb(255, 255, 255)',
    white: 'rgb(255, 255, 255)',
    White: 'rgb(255, 255, 255)',
    custom_rgb172_172_172: 'rgb(172, 172, 172)',
    'Custom #acacac': 'rgb(172, 172, 172)',
    custom_rgb0_0_0: 'rgb(0, 0, 0)',
    'Custom Color_18': 'rgb(0, 0, 0)',
    custom_rgb241_134_59: 'rgb(241, 134, 59)',
    'Custom Color_5': 'rgb(241, 134, 59)',
    peoplebitOceanBlue: 'rgb(50, 159, 179)',
    Peoplebit_Ocean_Blue: 'rgb(50, 159, 179)',
    studilyLightNavyBlue: 'rgb(56, 67, 84)',
    Studily_Light_Navy_Blue: 'rgb(56, 67, 84)',
    studilyWhiteShade1: 'rgb(227, 231, 249)',
    Studily_White_Shade_1: 'rgb(227, 231, 249)',
    studilyWhiteShade5: 'rgb(243, 245, 249)',
    Studily_White_Shade_5: 'rgb(243, 245, 249)',
    custom_rgb0_43_75: 'rgb(0, 43, 75)',
    'Custom #002b4b': 'rgb(0, 43, 75)',
    custom_rgb170_167_163: 'rgb(170, 167, 163)',
    'Custom #aaa7a3': 'rgb(170, 167, 163)',
    custom_rgb247_253_249: 'rgb(247, 253, 249)',
    'Custom Color_21': 'rgb(247, 253, 249)',
    peoplebitEarthyBrown: 'rgb(95, 90, 83)',
    Peoplebit_Earthy_Brown: 'rgb(95, 90, 83)',
    studilyForrestShade: 'rgb(126, 209, 172)',
    Studily_Forrest_Shade: 'rgb(126, 209, 172)',
    custom_rgb242_164_93: 'rgb(242, 164, 93)',
    'Custom Color_13': 'rgb(242, 164, 93)',
    getFitOrange: 'rgb(254, 151, 15)',
    'GetFit Orange': 'rgb(254, 151, 15)',
    peoplebitLightStoneGray: 'rgb(216, 216, 216)',
    Peoplebit_Light_Stone_Gray: 'rgb(216, 216, 216)',
    custom_rgb245_249_255: 'rgb(245, 249, 255)',
    'Custom Color_6': 'rgb(245, 249, 255)',
    studilyBrightBlue: 'rgb(86, 184, 244)',
    Studily_Bright_Blue: 'rgb(86, 184, 244)',
    studilyLightNavySecondary: 'rgb(80, 118, 198)',
    Studily_Light_Navy_Secondary: 'rgb(80, 118, 198)',
    studilyPrimaryUIDark: 'rgb(55, 66, 83)',
    Studily_Primary_UI_Dark: 'rgb(55, 66, 83)',
    studilyWhiteShade2: 'rgb(230, 235, 244)',
    Studily_White_Shade_2: 'rgb(230, 235, 244)',
    switchColorForSettingsPage: 'rgb(96, 212, 204)',
    Switch_Color_for_Settings_Page: 'rgb(96, 212, 204)',
    custom_rgb255_255_255: 'rgb(255, 255, 255)',
    'Custom Color': 'rgb(255, 255, 255)',
    custom_rgb108_108_108: 'rgb(108, 108, 108)',
    'Custom Color_14': 'rgb(108, 108, 108)',
    custom_rgb62_193_99: 'rgb(62, 193, 99)',
    'Custom Color_20': 'rgb(62, 193, 99)',
    peopleBitLightBrown: 'rgb(138, 132, 125)',
    People_Bit_Light_Brown: 'rgb(138, 132, 125)',
    peoplebitStoneGray: 'rgb(172, 172, 172)',
    Peoplebit_Stone_Gray: 'rgb(172, 172, 172)',
    custom_rgb50_159_179: 'rgb(50, 159, 179)',
    'Custom #329fb3': 'rgb(50, 159, 179)',
    peoplebitLightGray: 'rgb(219, 219, 219)',
    Peoplebit_Light_Gray: 'rgb(219, 219, 219)',
    appGreen: 'rgb(0, 204, 154)',
    'App Green': 'rgb(0, 204, 154)',
    custom_rgb240_100_84: 'rgb(240, 100, 84)',
    'Custom #f06454': 'rgb(240, 100, 84)',
    peoplebitDarkBlue: 'rgb(0, 43, 75)',
    Peoplebit_Dark_Blue: 'rgb(0, 43, 75)',
    studilyDarkPrimary: 'rgb(17, 17, 17)',
    Studily_Dark_Primary: 'rgb(17, 17, 17)',
    studilyMintGreen: 'rgb(97, 214, 204)',
    Studily_Mint_Green: 'rgb(97, 214, 204)',
    studilyOpacity25: 'rgba(0, 0, 0, 0.25)',
    Studily_Opacity_25: 'rgba(0, 0, 0, 0.25)',
    studilyPrimary: 'rgb(85, 108, 246)',
    Studily_Primary: 'rgb(85, 108, 246)',
    studilySilverWhite: 'rgb(236, 240, 246)',
    Studily_Silver_White: 'rgb(236, 240, 246)',
    custom_rgb6_48_63: 'rgb(6, 48, 63)',
    'Custom #06303f': 'rgb(6, 48, 63)',
    custom_rgb20_73_92: 'rgb(20, 73, 92)',
    'Custom #14495c': 'rgb(20, 73, 92)',
    custom_rgb225_236_253: 'rgb(225, 236, 253)',
    'Custom Color_10': 'rgb(225, 236, 253)',
    custom_rgb86_198_237: 'rgb(86, 198, 237)',
    'Custom Color_12': 'rgb(86, 198, 237)',
    custom_rgb241_241_241: 'rgb(241, 241, 241)',
    'Custom Color_7': 'rgb(241, 241, 241)',
    custom_rgb219_219_219: 'rgb(219, 219, 219)',
    'Custom #dbdbdb': 'rgb(219, 219, 219)',
    custom_rgb255_255_255: 'rgb(255, 255, 255)',
    'Custom #ffffff': 'rgb(255, 255, 255)',
    studilyWhiteShade3: 'rgb(231, 235, 241)',
    Studily_White_Shade_3: 'rgb(231, 235, 241)',
    custom_rgb95_90_83: 'rgb(95, 90, 83)',
    'Custom #5f5a53': 'rgb(95, 90, 83)',
    peoplebitOrange: 'rgb(240, 100, 84)',
    Peoplebit_Orange: 'rgb(240, 100, 84)',
    settingsIconBGColor: 'rgb(175, 185, 249)',
    Settings_Icon_BG_Color: 'rgb(175, 185, 249)',
    studilyDarkUI: 'rgb(17, 17, 17)',
    Studily_Dark_UI: 'rgb(17, 17, 17)',
    studilyEmerald: 'rgb(126, 209, 172)',
    Studily_Emerald: 'rgb(126, 209, 172)',
    custom_rgb229_229_229: 'rgb(229, 229, 229)',
    'Custom Color_2': 'rgb(229, 229, 229)',
    custom_rgb211_83_109: 'rgb(211, 83, 109)',
    'Custom #d3536d': 'rgb(211, 83, 109)',
    iconOpacity: 'rgba(0, 0, 0, 0.25)',
    Icon_Opacity: 'rgba(0, 0, 0, 0.25)',
    opacityBlueForButton: 'rgba(85, 108, 246, 0.05)',
    Opacity_Blue_for_Button: 'rgba(85, 108, 246, 0.05)',
    peoplebitLightBrown: 'rgb(170, 167, 163)',
    Peoplebit_Light_Brown: 'rgb(170, 167, 163)',
    studilySecondaryUI: 'rgb(133, 141, 152)',
    Studily_Secondary_UI: 'rgb(133, 141, 152)',
    custom_rgb189_189_189: 'rgb(189, 189, 189)',
    'Custom Color_17': 'rgb(189, 189, 189)',
    optionSelectedColor: 'rgb(97, 214, 204)',
    Option_Selected_Color: 'rgb(97, 214, 204)',
    peoplebitSapphire: 'rgb(0, 55, 75)',
    Peoplebit_Sapphire: 'rgb(0, 55, 75)',
    studilyBlueNight: 'rgb(80, 118, 198)',
    Studily_Blue_Night: 'rgb(80, 118, 198)',
    inputArea: 'rgb(226, 247, 238)',
    Input_area: 'rgb(226, 247, 238)',
  },
  Brand: {
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    'Medium Inverse': 'rgba(255, 255, 255, 0.87)',
    strongInverse: 'rgba(255, 255, 255, 1)',
    'Strong Inverse': 'rgba(255, 255, 255, 1)',
    lightInverse: 'rgba(255, 255, 255, 0.68)',
    'Light Inverse': 'rgba(255, 255, 255, 0.68)',
    background: 'rgba(251, 252, 253, 1)',
    Background: 'rgba(251, 252, 253, 1)',
    lightInverse: 'rgba(255, 255, 255, 0.68)',
    Light_Inverse: 'rgba(255, 255, 255, 0.68)',
    primary: 'rgb(117, 161, 185)',
    Primary: 'rgb(117, 161, 185)',
    secondary: 'rgb(217, 180, 74)',
    Secondary: 'rgb(217, 180, 74)',
    divider: 'rgba(234, 237, 242, 1)',
    Divider: 'rgba(234, 237, 242, 1)',
    error: 'rgba(255, 69, 100, 1)',
    Error: 'rgba(255, 69, 100, 1)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    Medium_Inverse: 'rgba(255, 255, 255, 0.87)',
    light: 'rgba(165, 173, 183, 1)',
    Light: 'rgba(165, 173, 183, 1)',
    strong: 'rgba(18, 20, 44, 1)',
    Strong: 'rgba(18, 20, 44, 1)',
    strongInverse: 'rgba(255, 255, 255, 1)',
    Strong_Inverse: 'rgba(255, 255, 255, 1)',
    medium: 'rgba(70, 78, 88, 1)',
    Medium: 'rgba(70, 78, 88, 1)',
    surface: 'rgba(255, 255, 255, 1)',
    Surface: 'rgba(255, 255, 255, 1)',
  },
  Slate: {
    _50: '#f8fafc',
    50: '#f8fafc',
    _100: '#f1f5f9',
    100: '#f1f5f9',
    _200: '#e2e8f0',
    200: '#e2e8f0',
    _300: '#cbd5e1',
    300: '#cbd5e1',
    _400: '#94a3b8',
    400: '#94a3b8',
    _500: '#64748b',
    500: '#64748b',
    _600: '#475569',
    600: '#475569',
    _700: '#334155',
    700: '#334155',
    _800: '#1e293b',
    800: '#1e293b',
    _900: '#0f172a',
    900: '#0f172a',
    _950: '#020617',
    950: '#020617',
  },
  Gray: {
    _50: '#f9fafb',
    50: '#f9fafb',
    _100: '#f3f4f6',
    100: '#f3f4f6',
    _200: '#e5e7eb',
    200: '#e5e7eb',
    _300: '#d1d5db',
    300: '#d1d5db',
    _400: '#9ca3af',
    400: '#9ca3af',
    _500: '#6b7280',
    500: '#6b7280',
    _600: '#4b5563',
    600: '#4b5563',
    _700: '#374151',
    700: '#374151',
    _800: '#1f2937',
    800: '#1f2937',
    _900: '#111827',
    900: '#111827',
    _950: '#030712',
    950: '#030712',
  },
  Zinc: {
    _50: '#fafafa',
    50: '#fafafa',
    _100: '#f4f4f5',
    100: '#f4f4f5',
    _200: '#e4e4e7',
    200: '#e4e4e7',
    _300: '#d4d4d8',
    300: '#d4d4d8',
    _400: '#a1a1aa',
    400: '#a1a1aa',
    _500: '#71717a',
    500: '#71717a',
    _600: '#52525b',
    600: '#52525b',
    _700: '#3f3f46',
    700: '#3f3f46',
    _800: '#27272a',
    800: '#27272a',
    _900: '#18181b',
    900: '#18181b',
    _950: '#09090b',
    950: '#09090b',
  },
  Neutral: {
    _50: '#fafafa',
    50: '#fafafa',
    _100: '#f5f5f5',
    100: '#f5f5f5',
    _200: '#e5e5e5',
    200: '#e5e5e5',
    _300: '#d4d4d4',
    300: '#d4d4d4',
    _400: '#a3a3a3',
    400: '#a3a3a3',
    _500: '#737373',
    500: '#737373',
    _600: '#525252',
    600: '#525252',
    _700: '#404040',
    700: '#404040',
    _800: '#262626',
    800: '#262626',
    _900: '#171717',
    900: '#171717',
    _950: '#0a0a0a',
    950: '#0a0a0a',
  },
  Stone: {
    _50: '#fafaf9',
    50: '#fafaf9',
    _100: '#f5f5f4',
    100: '#f5f5f4',
    _200: '#e7e5e4',
    200: '#e7e5e4',
    _300: '#d6d3d1',
    300: '#d6d3d1',
    _400: '#a8a29e',
    400: '#a8a29e',
    _500: '#78716c',
    500: '#78716c',
    _600: '#57534e',
    600: '#57534e',
    _700: '#44403c',
    700: '#44403c',
    _800: '#292524',
    800: '#292524',
    _900: '#1c1917',
    900: '#1c1917',
    _950: '#0c0a09',
    950: '#0c0a09',
  },
  Red: {
    _50: '#fef2f2',
    50: '#fef2f2',
    _100: '#fee2e2',
    100: '#fee2e2',
    _200: '#fecaca',
    200: '#fecaca',
    _300: '#fca5a5',
    300: '#fca5a5',
    _400: '#f87171',
    400: '#f87171',
    _500: '#ef4444',
    500: '#ef4444',
    _600: '#dc2626',
    600: '#dc2626',
    _700: '#b91c1c',
    700: '#b91c1c',
    _800: '#991b1b',
    800: '#991b1b',
    _900: '#7f1d1d',
    900: '#7f1d1d',
    _950: '#450a0a',
    950: '#450a0a',
  },
  Orange: {
    _50: '#fff7ed',
    50: '#fff7ed',
    _100: '#ffedd5',
    100: '#ffedd5',
    _200: '#fed7aa',
    200: '#fed7aa',
    _300: '#fdba74',
    300: '#fdba74',
    _400: '#fb923c',
    400: '#fb923c',
    _500: '#f97316',
    500: '#f97316',
    _600: '#ea580c',
    600: '#ea580c',
    _700: '#c2410c',
    700: '#c2410c',
    _800: '#9a3412',
    800: '#9a3412',
    _900: '#7c2d12',
    900: '#7c2d12',
    _950: '#431407',
    950: '#431407',
  },
  Amber: {
    _50: '#fffbeb',
    50: '#fffbeb',
    _100: '#fef3c7',
    100: '#fef3c7',
    _200: '#fde68a',
    200: '#fde68a',
    _300: '#fcd34d',
    300: '#fcd34d',
    _400: '#fbbf24',
    400: '#fbbf24',
    _500: '#f59e0b',
    500: '#f59e0b',
    _600: '#d97706',
    600: '#d97706',
    _700: '#b45309',
    700: '#b45309',
    _800: '#92400e',
    800: '#92400e',
    _900: '#78350f',
    900: '#78350f',
    _950: '#451a03',
    950: '#451a03',
  },
  Yellow: {
    _50: '#fefce8',
    50: '#fefce8',
    _100: '#fef9c3',
    100: '#fef9c3',
    _200: '#fef08a',
    200: '#fef08a',
    _300: '#fde047',
    300: '#fde047',
    _400: '#facc15',
    400: '#facc15',
    _500: '#eab308',
    500: '#eab308',
    _600: '#ca8a04',
    600: '#ca8a04',
    _700: '#a16207',
    700: '#a16207',
    _800: '#854d0e',
    800: '#854d0e',
    _900: '#713f12',
    900: '#713f12',
    _950: '#422006',
    950: '#422006',
  },
  Lime: {
    _50: '#f7fee7',
    50: '#f7fee7',
    _100: '#ecfccb',
    100: '#ecfccb',
    _200: '#d9f99d',
    200: '#d9f99d',
    _300: '#bef264',
    300: '#bef264',
    _400: '#a3e635',
    400: '#a3e635',
    _500: '#84cc16',
    500: '#84cc16',
    _600: '#65a30d',
    600: '#65a30d',
    _700: '#4d7c0f',
    700: '#4d7c0f',
    _800: '#3f6212',
    800: '#3f6212',
    _900: '#365314',
    900: '#365314',
    _950: '#1a2e05',
    950: '#1a2e05',
  },
  Green: {
    _50: '#f0fdf4',
    50: '#f0fdf4',
    _100: '#dcfce7',
    100: '#dcfce7',
    _200: '#bbf7d0',
    200: '#bbf7d0',
    _300: '#86efac',
    300: '#86efac',
    _400: '#4ade80',
    400: '#4ade80',
    _500: '#22c55e',
    500: '#22c55e',
    _600: '#16a34a',
    600: '#16a34a',
    _700: '#15803d',
    700: '#15803d',
    _800: '#166534',
    800: '#166534',
    _900: '#14532d',
    900: '#14532d',
    _950: '#052e16',
    950: '#052e16',
  },
  Emerald: {
    _50: '#ecfdf5',
    50: '#ecfdf5',
    _100: '#d1fae5',
    100: '#d1fae5',
    _200: '#a7f3d0',
    200: '#a7f3d0',
    _300: '#6ee7b7',
    300: '#6ee7b7',
    _400: '#34d399',
    400: '#34d399',
    _500: '#10b981',
    500: '#10b981',
    _600: '#059669',
    600: '#059669',
    _700: '#047857',
    700: '#047857',
    _800: '#065f46',
    800: '#065f46',
    _900: '#064e3b',
    900: '#064e3b',
    _950: '#022c22',
    950: '#022c22',
  },
  Teal: {
    _50: '#f0fdfa',
    50: '#f0fdfa',
    _100: '#ccfbf1',
    100: '#ccfbf1',
    _200: '#99f6e4',
    200: '#99f6e4',
    _300: '#5eead4',
    300: '#5eead4',
    _400: '#2dd4bf',
    400: '#2dd4bf',
    _500: '#14b8a6',
    500: '#14b8a6',
    _600: '#0d9488',
    600: '#0d9488',
    _700: '#0f766e',
    700: '#0f766e',
    _800: '#115e59',
    800: '#115e59',
    _900: '#134e4a',
    900: '#134e4a',
    _950: '#042f2e',
    950: '#042f2e',
  },
  Cyan: {
    _50: '#ecfeff',
    50: '#ecfeff',
    _100: '#cffafe',
    100: '#cffafe',
    _200: '#a5f3fc',
    200: '#a5f3fc',
    _300: '#67e8f9',
    300: '#67e8f9',
    _400: '#22d3ee',
    400: '#22d3ee',
    _500: '#06b6d4',
    500: '#06b6d4',
    _600: '#0891b2',
    600: '#0891b2',
    _700: '#0e7490',
    700: '#0e7490',
    _800: '#155e75',
    800: '#155e75',
    _900: '#164e63',
    900: '#164e63',
    _950: '#083344',
    950: '#083344',
  },
  Sky: {
    _50: '#f0f9ff',
    50: '#f0f9ff',
    _100: '#e0f2fe',
    100: '#e0f2fe',
    _200: '#bae6fd',
    200: '#bae6fd',
    _300: '#7dd3fc',
    300: '#7dd3fc',
    _400: '#38bdf8',
    400: '#38bdf8',
    _500: '#0ea5e9',
    500: '#0ea5e9',
    _600: '#0284c7',
    600: '#0284c7',
    _700: '#0369a1',
    700: '#0369a1',
    _800: '#075985',
    800: '#075985',
    _900: '#0c4a6e',
    900: '#0c4a6e',
    _950: '#082f49',
    950: '#082f49',
  },
  Blue: {
    _50: '#eff6ff',
    50: '#eff6ff',
    _100: '#dbeafe',
    100: '#dbeafe',
    _200: '#bfdbfe',
    200: '#bfdbfe',
    _300: '#93c5fd',
    300: '#93c5fd',
    _400: '#60a5fa',
    400: '#60a5fa',
    _500: '#3b82f6',
    500: '#3b82f6',
    _600: '#2563eb',
    600: '#2563eb',
    _700: '#1d4ed8',
    700: '#1d4ed8',
    _800: '#1e40af',
    800: '#1e40af',
    _900: '#1e3a8a',
    900: '#1e3a8a',
    _950: '#172554',
    950: '#172554',
  },
  Indigo: {
    _50: '#eef2ff',
    50: '#eef2ff',
    _100: '#e0e7ff',
    100: '#e0e7ff',
    _200: '#c7d2fe',
    200: '#c7d2fe',
    _300: '#a5b4fc',
    300: '#a5b4fc',
    _400: '#818cf8',
    400: '#818cf8',
    _500: '#6366f1',
    500: '#6366f1',
    _600: '#4f46e5',
    600: '#4f46e5',
    _700: '#4338ca',
    700: '#4338ca',
    _800: '#3730a3',
    800: '#3730a3',
    _900: '#312e81',
    900: '#312e81',
    _950: '#1e1b4b',
    950: '#1e1b4b',
  },
  Violet: {
    _50: '#f5f3ff',
    50: '#f5f3ff',
    _100: '#ede9fe',
    100: '#ede9fe',
    _200: '#ddd6fe',
    200: '#ddd6fe',
    _300: '#c4b5fd',
    300: '#c4b5fd',
    _400: '#a78bfa',
    400: '#a78bfa',
    _500: '#8b5cf6',
    500: '#8b5cf6',
    _600: '#7c3aed',
    600: '#7c3aed',
    _700: '#6d28d9',
    700: '#6d28d9',
    _800: '#5b21b6',
    800: '#5b21b6',
    _900: '#4c1d95',
    900: '#4c1d95',
    _950: '#2e1065',
    950: '#2e1065',
  },
  Purple: {
    _50: '#faf5ff',
    50: '#faf5ff',
    _100: '#f3e8ff',
    100: '#f3e8ff',
    _200: '#e9d5ff',
    200: '#e9d5ff',
    _300: '#d8b4fe',
    300: '#d8b4fe',
    _400: '#c084fc',
    400: '#c084fc',
    _500: '#a855f7',
    500: '#a855f7',
    _600: '#9333ea',
    600: '#9333ea',
    _700: '#7e22ce',
    700: '#7e22ce',
    _800: '#6b21a8',
    800: '#6b21a8',
    _900: '#581c87',
    900: '#581c87',
    _950: '#3b0764',
    950: '#3b0764',
  },
  Fuchsia: {
    _50: '#fdf4ff',
    50: '#fdf4ff',
    _100: '#fae8ff',
    100: '#fae8ff',
    _200: '#f5d0fe',
    200: '#f5d0fe',
    _300: '#f0abfc',
    300: '#f0abfc',
    _400: '#e879f9',
    400: '#e879f9',
    _500: '#d946ef',
    500: '#d946ef',
    _600: '#c026d3',
    600: '#c026d3',
    _700: '#a21caf',
    700: '#a21caf',
    _800: '#86198f',
    800: '#86198f',
    _900: '#701a75',
    900: '#701a75',
    _950: '#4a044e',
    950: '#4a044e',
  },
  Pink: {
    _50: '#fdf2f8',
    50: '#fdf2f8',
    _100: '#fce7f3',
    100: '#fce7f3',
    _200: '#fbcfe8',
    200: '#fbcfe8',
    _300: '#f9a8d4',
    300: '#f9a8d4',
    _400: '#f472b6',
    400: '#f472b6',
    _500: '#ec4899',
    500: '#ec4899',
    _600: '#db2777',
    600: '#db2777',
    _700: '#be185d',
    700: '#be185d',
    _800: '#9d174d',
    800: '#9d174d',
    _900: '#831843',
    900: '#831843',
    _950: '#500724',
    950: '#500724',
  },
  Rose: {
    _50: '#fff1f2',
    50: '#fff1f2',
    _100: '#ffe4e6',
    100: '#ffe4e6',
    _200: '#fecdd3',
    200: '#fecdd3',
    _300: '#fda4af',
    300: '#fda4af',
    _400: '#fb7185',
    400: '#fb7185',
    _500: '#f43f5e',
    500: '#f43f5e',
    _600: '#e11d48',
    600: '#e11d48',
    _700: '#be123c',
    700: '#be123c',
    _800: '#9f1239',
    800: '#9f1239',
    _900: '#881337',
    900: '#881337',
    _950: '#4c0519',
    950: '#4c0519',
  },
};
